import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  //elem = this.renderer2.createElement(this.document.head, "meta");
  constructor(private title: Title, private meta: Meta,
    @Inject(DOCUMENT) private document: any,
  ) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ property: 'description', content: desc });
  }

  updateOgUrl(url: string) {

    //  this.renderer2.setAttribute(this.elem, 'og:url', url);
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  updateOgTitle(title: string) {
    //  this.renderer2.setAttribute(this.elem, 'og:title', title);
    this.meta.updateTag({ property: 'og:title', content: title });
  }
  updateOgDescription(description: string) {
    this.meta.updateTag({ property: 'og:description', content: description });
  }

  updateOgImage(imageUrl: string) {
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
  }

  updateFaviconIcon(imageUrl: string) {
    this.document.getElementById('appFavicon').setAttribute('href', imageUrl);
  }

}
