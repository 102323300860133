import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AnalyticSettingsService } from 'src/app/appServices/analytic-settings.service';
import { RestaurantSettingsService } from 'src/app/appServices/restaurant-settings.service';
import { TenantService } from 'src/app/appServices/tenant.service';
import { UtilityService } from 'src/app/appServices/utility.service';
import { DeliveryTypeStorage } from 'src/app/appStorage/delivery-type.storage';
import { RestaurantBasicInfoStorage } from 'src/app/appStorage/restaurant-basic-info.storage';
import { TenentContextService } from 'src/app/auth/tenant-identifier/tenent-context.service';
import { TokenService } from 'src/app/auth/token.service';

@Injectable({
  providedIn: 'root',
})
export class TenantInitService {
  restaurantInfo;
  analyticSettings: any;

  constructor(
    @Inject(APP_BASE_HREF) private baseHref: string,
    private tenantContextService: TenentContextService,
    private tenantService: TenantService,
    private basicInfoStorage: RestaurantBasicInfoStorage,
    private restaurantSettingsService: RestaurantSettingsService,
    private tokenService: TokenService,
    private analyticSettingsService: AnalyticSettingsService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  init() {
    this.getTenantName();
    this.subscribeTenantName();
  }

  /**
   * get Tenant Name
   */
  getTenantName() {
    if (this.baseHref && this.baseHref !== '/') {
      const baseUrl = this.baseHref.split('/');
      // this.tenantContextService.tenant.next(baseUrl[1]);
      this.tenantService.getTenantByDomain(baseUrl[1]).subscribe(res => {
        if (res) {
          this.tenantContextService.tenant.next(res);
          this.tenantContextService.domain.next(baseUrl[1]);
        }
      },
        (err) => {
          console.log('err', err);
          console.log('No tenant found');
        });
    } else {
      this.tenantService
        .getTenantByUrl(this.document.location.hostname)
        .subscribe(
          (res) => {
            if (res) {
              this.tenantContextService.tenant.next(res);
              this.tenantContextService.domain.next(null);
            }
          },
          (err) => {
            console.log('err', err);
            console.log('No tenant found');
          }
        );
    }
  }

  /**
   * subscribe tenant name
   */
  subscribeTenantName() {
    this.tenantContextService.tenant.subscribe((res) => {
      if (res) {
        this.subscribeBasicInfo();
      }
    });
  }

  /**
   * subscribe basic info
   */
  subscribeBasicInfo() {
    this.basicInfoStorage.getBasicInfo();
    this.basicInfoStorage.restaurantBasicInfo.subscribe((res) => {
      if (res) {
        this.restaurantInfo = res;
        this.appItialization();
      }
    });
  }

  /**
   * intialize app
   * call required services
   */
  appItialization(): void {
    this.restaurantSettingsService.getTax();
    this.restaurantSettingsService.getTipsEnableConfig();
    this.restaurantSettingsService.getStripeProcessingFee();
    this.getAnalyticSettings();
    this.tokenService.getUserProfile();
  }

  /**
   * get analytics settings
   */
  getAnalyticSettings() {
    this.analyticSettingsService.getAnalyticSettings().subscribe((res) => {
      this.analyticSettings = res;
    });
  }
}
