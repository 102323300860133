import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PromptComponent } from '../includes/popups/prompt/prompt.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JoinLeaderboardComponent } from '../includes/popups/join-leaderboard/join-leaderboard.component';


@Injectable({
  providedIn: 'root'
})
export class DesignUtilityServices {

  // New Subjects for Updated Header Concepts
  headerColor = new BehaviorSubject('');  // For Header Color
  theme = new BehaviorSubject('dark-theme');  // For Header Color

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar) { }
  //------------------------------------------// 
  // Custom Prompt                            //
  //------------------------------------------//
  openPrompt(promptData): Observable<any> {
    const dialogRef = this.dialog.open(PromptComponent, {
      data: promptData
    });

    return dialogRef.afterClosed()
  }



  //------------------------------------------// 
  // Custom SnakeBar                          //
  //------------------------------------------//
  openSnackBar(message: string, action?: string, duration?: number): void {
    this.snackBar.open(message, (action ? action : 'Close'), {
      duration: (duration ? duration : 2) * 1000
    });
  }


  joinLeaderboard(): Observable<any> {
    const dialogRef = this.dialog.open(JoinLeaderboardComponent, {
      maxWidth: '500px',
      width: '90%',
      disableClose: true
    });

    return dialogRef.afterClosed()
  }

   
}
