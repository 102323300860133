import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  api = this.apiConfig.getBaseUrl;

  constructor(
    private apiConfig: ApiConfigService) {
  }

  getAllOrders(): Observable<any> {
    return this.apiConfig.get(this.api + 'order', { withCredentials: true });
  }
  createOrder(order, paymentGatewayName): Observable<any> {
    return this.apiConfig.post(this.api + 'order/website/'+paymentGatewayName, order, { withCredentials: true });
  }
  
  markOrderAsPaidByManualCard(id, paymentModel): Observable<any> {
    return this.apiConfig.post(
      this.api + 'order/paid/manual-card/' + id,
      paymentModel,
      { withCredentials: true }
    );
  }

  // trackOrder(id): Observable<any> {
  //   return this.apiConfig.get(this.api + '/order/track/' + id, { withCredentials: true });
  // }

  getOrderByEncryptedId(id: string): Observable<any> {
    return this.apiConfig.get(this.api + 'order/encrypted/' + id);
  }

  makeOnlinePayment(onliePaymentModel): Observable<any> {
    return this.apiConfig.post(this.api + 'order/online-payment', onliePaymentModel);
  }

  getPromoCode(storeId:number, code): Observable<any> {
    return this.apiConfig.get(this.api + 'promo-code/customer/' + storeId + '/' + code, { withCredentials: true });
  }
}
