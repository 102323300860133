import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

import { AppServerModule } from './app/app.server.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@teamhive/lottie-player/loader';

if (environment.production) {
  enableProdMode();
}
defineCustomElements(window);

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});

// document.addEventListener("DOMContentLoaded", () => {
//   platformBrowserDynamic()
//     .bootstrapModule(AppServerModule)
//     .catch(err => console.log(err));
// });