import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class BackupApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = req.url;
    const baseUrl = environment.baseUrl;
    const tenantUrl = environment.tenantApi;

    // Define a helper function to determine the appropriate backup URL
    const getBackupUrl = (url: string): string => {
      if (url.startsWith(baseUrl)) {
        return environment.backupBaseUrl + url.substr(baseUrl.length);
      } else if (url.startsWith(tenantUrl)) {
        return environment.backupTenantApi + url.substr(tenantUrl.length);
      }
      return url; // Default case, should not be hit if properly configured
    };

    if (requestUrl.startsWith(baseUrl) || requestUrl.startsWith(tenantUrl)) {
      return next.handle(req).pipe(
        timeout(5000), // Set timeout to 5 seconds
        catchError((error: any) => {
          // Switch to the appropriate backup URL based on the request URL
          const backupUrl = getBackupUrl(requestUrl);
          const backupRequest = req.clone({ url: backupUrl });

          return next.handle(backupRequest).pipe(
            catchError((backupError: HttpErrorResponse) => {
              // Handle backup API error here
              console.error('Backup API failed:', backupError);
              return throwError(() => new Error('Both primary and backup APIs failed'));
            })
          );
        })
      );
    } else {
      // If request URL does not start with base URL or tenant URL, proceed without backup
      return next.handle(req);
    }
  }
}
