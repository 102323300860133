<ngx-loading-bar [color]="'#ffcc00'" [height]="'5px'">
  <div class="loader-div"></div>
</ngx-loading-bar>
<mat-drawer-container class="example-container">
  <mat-drawer mode="side" [opened]="sidenav">Drawer content</mat-drawer>
  <mat-drawer-content>


    <div id="wrapper">
      <button class="toTop" [ngClass]="{'show': isShow}" (click)="gotoTop()"></button>
      <app-notification-bar *ngIf="showNotificationBar"></app-notification-bar>
      <app-header *ngIf="restaurantInfo"></app-header>
      <div class="main" *ngIf="restaurantInfo">
        <router-outlet></router-outlet>
      </div>
      <app-footer *ngIf="restaurantInfo"></app-footer>
    </div>

  </mat-drawer-content>
</mat-drawer-container>