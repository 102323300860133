<div class="address">
    <mat-card class="address-card bg-app">
        <button mat-icon-button (click)="onDelete()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-card-title>{{data.addressType.addressType}}</mat-card-title>

        <mat-card-content>
            <div class="item">
                <label class="opacity">{{data.address}}</label>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <ng-container>
                <button mat-button type="button" (click)="onEdit()">
                    <mat-icon>edit</mat-icon>Edit
                </button>
            </ng-container>
            <div class="flex-item-center align-center" *ngIf="data.defaultAddress">
                <mat-icon>done</mat-icon>&nbsp; Default
            </div>
            <button mat-button type="button" class="make-default" *ngIf="!data.defaultAddress" (click)="makeDefault(data)">
                Make Default
            </button>
        </mat-card-actions>
    </mat-card>
</div>