import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { IAddress } from '../appModels/IAddress';
import { MapsAPILoader } from '@agm/core';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  api = this.apiConfig.getBaseUrl + 'address/';
  defaultAddress = new BehaviorSubject(null);

  constructor(
    private apiConfig: ApiConfigService,
    private mapsAPILoader: MapsAPILoader) {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // console.log('map loaded');
    });
  }
  getAddresses(): Observable<any> {
    return this.apiConfig.get(this.api, { withCredentials: true });
  }
  addAddress(address): Observable<any> {
    return this.apiConfig.post(this.api, address, { withCredentials: true });
  }
  updateAddress(address: IAddress): Observable<any> {
    return this.apiConfig.put(this.api + address.id, address, { withCredentials: true });
  }
  deleteAddress(id: number): Observable<any> {
    return this.apiConfig.delete(this.api + id, { withCredentials: true });
  }

  /**
   * set default address
   */
  setDefaultAddress(): void {
    this.getAddresses().subscribe(res => {
      res.forEach(element => {
        if (element.defaultAddress) {
          this.defaultAddress.next(element);
        }
      });
      if (res.length === 0) {
        this.defaultAddress.next(null);
      }
    });
  }

  // /**
  //  * get default address
  //  * @param addressList 
  //  */
  // getDefalutAddress(addressList: IAddress[]): IAddress {
  //   return addressList.find(element => {
  //     if (element.defaultAddress) {
  //       return element;
  //     }
  //   });
  // }


  calculateDistance(point1, point2): string {

    const p1 = new google.maps.LatLng(
      point1.lat,
      point1.lng
    );
    const p2 = new google.maps.LatLng(
      point2.lat,
      point2.lng
    );
    return (
      google.maps.geometry.spherical.computeDistanceBetween(p1, p2)
    ).toFixed(2);
  }


}
