<div class="modal-container">
    <!-- <lottie-player class="lottie" src="https://assets3.lottiefiles.com/packages/lf20_wkebwzpz.json"
      background="transparent" speed="2" loop autoplay></lottie-player> -->
    <lottie-player class="lottie" src="https://assets8.lottiefiles.com/datafiles/Wv6eeBslW1APprw/data.json"
    background="transparent" speed="2" loop autoplay></lottie-player>
    <h1 mat-dialog-title class="color-green">Payment Successful!</h1>
    <p>Your payment was successful! 👍<br>
        👇Explore our website to order your <br>favorite food🌭🥧!!</p>

    <div mat-dialog-actions>
        <button mat-button class="btn-green" [mat-dialog-close]="true" routerLink="/">
            Order Online
        </button>
    </div>
</div>
