<div class="trending-carousel" *ngIf="products && products.length > 1">
    <div class="carousel-text">
        <h3>{{title}}</h3>
        <!-- <div class="navigation">
            <div class="nav left"></div>
            <div class="nav right"></div>
        </div> -->
    </div>
    <swiper [slidesPerView]="swiperConfig.slidesPerView" [spaceBetween]="swiperConfig.spaceBetween"
        [pagination]="swiperConfig.pagination" [breakpoints]="swiperConfig.breakpoints" class="mySwiper">
        <ng-container *ngFor="let pItem of products">
            <ng-template swiperSlide class="product-list" *ngIf="pItem.id !== notInclude">
                <div class="item bg-app foreground" (click)="gotToProduct(pItem)" matRipple>
                    <figure>
                        <img [src]="S3_URL  + basicInfo.bucketName + '/' + pItem.productImageList[0]?.imageLink" alt=""
                            width="120">
                    </figure>
                    <div class="details">
                        <span class="name">
                            {{pItem.name}}
                        </span>
                        <span class="price primary">
                            {{pItem.basePrice | currency: basicInfo.currency}}
                        </span>
                        <button class="add mat-elevation-z0" mat-icon-button mat-stroked-button color="primary">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-template>
        </ng-container>



    </swiper>
    <!-- <app-swiper [options]="carousel" [id]="'trending'">
        <div class="swiper-slide">
            <mat-card class="card-product">
                <figure class="img">
                    <img mat-card-image src="assets/images/remove/burger.png">
                </figure>
                <mat-card-content>
                    <h4 class="heading">
                        Bacon BBQ Burger
                    </h4>
                </mat-card-content>
                <mat-card-actions>
                    <div class="row">
                        <span class="price {{theme=='dark-theme'?'primary': 'accent'}}">$4.90</span>
                        <button mat-icon-button>
                            <mat-icon>shopping_bag</mat-icon>
                        </button>
                    </div>
                </mat-card-actions>
                </mat-card>
        </div>
        
        <div class="swiper-slide">
            <mat-card class="card-product">
                <figure class="img">
                    <img mat-card-image src="assets/images/remove/burger.png">
                </figure>
                <mat-card-content>
                    <h4 class="heading">
                        Bacon BBQ Burger
                    </h4>
                </mat-card-content>
                <mat-card-actions>
                    <div class="row">
                        <span class="price {{theme=='dark-theme'?'primary': 'accent'}}">$4.90</span>
                        <button mat-icon-button>
                            <mat-icon>shopping_bag</mat-icon>
                        </button>
                    </div>
                </mat-card-actions>
                </mat-card>
        </div>
        
        <div class="swiper-slide">
            <mat-card class="card-product">
                <figure class="img">
                    <img mat-card-image src="assets/images/remove/burger.png">
                </figure>
                <mat-card-content>
                    <h4 class="heading">
                        Bacon BBQ Burger
                    </h4>
                </mat-card-content>
                <mat-card-actions>
                    <div class="row">
                        <span class="price {{theme=='dark-theme'?'primary': 'accent'}}">$4.90</span>
                        <button mat-icon-button>
                            <mat-icon>shopping_bag</mat-icon>
                        </button>
                    </div>
                </mat-card-actions>
                </mat-card>
        </div>
        
        <div class="swiper-slide">
            <mat-card class="card-product">
                <figure class="img">
                    <img mat-card-image src="assets/images/remove/burger.png">
                </figure>
                <mat-card-content>
                    <h4 class="heading">
                        Bacon BBQ Burger
                    </h4>
                </mat-card-content>
                <mat-card-actions>
                    <div class="row">
                        <span class="price {{theme=='dark-theme'?'primary': 'accent'}}">$4.90</span>
                        <button mat-icon-button>
                            <mat-icon>shopping_bag</mat-icon>
                        </button>
                    </div>
                </mat-card-actions>
                </mat-card>
        </div>
        
        <div class="swiper-slide">
            <mat-card class="card-product">
                <figure class="img">
                    <img mat-card-image src="assets/images/remove/burger.png">
                </figure>
                <mat-card-content>
                    <h4 class="heading">
                        Bacon BBQ Burger
                    </h4>
                </mat-card-content>
                <mat-card-actions>
                    <div class="row">
                        <span class="price {{theme=='dark-theme'?'primary': 'accent'}}">$4.90</span>
                        <button mat-icon-button>
                            <mat-icon>shopping_bag</mat-icon>
                        </button>
                    </div>
                </mat-card-actions>
                </mat-card>
        </div>
        
        <div class="swiper-slide">
            <mat-card class="card-product">
                <figure class="img">
                    <img mat-card-image src="assets/images/remove/burger.png">
                </figure>
                <mat-card-content>
                    <h4 class="heading">
                        Bacon BBQ Burger
                    </h4>
                </mat-card-content>
                <mat-card-actions>
                    <div class="row">
                        <span class="price {{theme=='dark-theme'?'primary': 'accent'}}">$4.90</span>
                        <button mat-icon-button>
                            <mat-icon>shopping_bag</mat-icon>
                        </button>
                    </div>
                </mat-card-actions>
                </mat-card>
        </div>
        
        <div class="swiper-slide">
            <mat-card class="card-product">
                <figure class="img">
                    <img mat-card-image src="assets/images/remove/burger.png">
                </figure>
                <mat-card-content>
                    <h4 class="heading">
                        Bacon BBQ Burger
                    </h4>
                </mat-card-content>
                <mat-card-actions>
                    <div class="row">
                        <span class="price {{theme=='dark-theme'?'primary': 'accent'}}">$4.90</span>
                        <button mat-icon-button>
                            <mat-icon>shopping_bag</mat-icon>
                        </button>
                    </div>
                </mat-card-actions>
                </mat-card>
        </div>
    </app-swiper> -->
</div>