import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationBarService } from 'src/app/core/appInit/notification-bar.service';
import { UpdateProfileComponent } from '../popups/update-profile/update-profile.component';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit {

  theme:string;
  constructor(private _notificationBarService:NotificationBarService,
  public dialog: MatDialog) { }

  ngOnInit() {
  }

  onCloseNotificationBar(){
    this._notificationBarService.onCloseNotificationBar();
  }

  openProfileDialog() {
    this.dialog.open(UpdateProfileComponent, {
      disableClose: true
    });
  }
}
