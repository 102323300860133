import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RestaurantInfoService } from '../appServices/restaurant-info.service';
import { Title, Meta } from '@angular/platform-browser';
import { SeoService } from '../appServices/seo.service';

import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RestaurantBasicInfoStorage {
    restaurantBasicInfo = new BehaviorSubject(null);
    isBrowser: boolean;

    constructor(
        private restaurantInfoService: RestaurantInfoService,
        private seoService: SeoService,
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    getBasicInfo(): void {
        if (!this.restaurantBasicInfo.value) {
            this.setRestaurantBasicInfoFromApi();
        }


    }

    setRestaurantBasicInfoFromApi(): void {
        this.restaurantInfoService.getBasicInfo().subscribe(res => {
            if (res) {
                this.restaurantBasicInfo.next(res);
                this.updateMetaData();
            }
        });
    }




    updateMetaData() {
        const logo = this.restaurantBasicInfo.value.darkLogo ? this.restaurantBasicInfo.value.darkLogo : this.restaurantBasicInfo.value.imageLink;
        this.seoService.updateDescription(this.restaurantBasicInfo.value.description);
        this.seoService.updateOgDescription(this.restaurantBasicInfo.value.description);
        this.seoService.updateTitle(this.restaurantBasicInfo.value.restaurantName);
        this.seoService.updateOgTitle(this.restaurantBasicInfo.value.restaurantName);
        this.seoService.updateOgImage(environment.S3_URL + '' + this.restaurantBasicInfo.value.bucketName + '/' + logo);
        this.seoService.updateFaviconIcon(environment.S3_URL + '' + this.restaurantBasicInfo.value.bucketName + '/' + logo);
    }

}