import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IAddress } from 'src/app/appModels/IAddress';
import { SelectAddressComponent } from 'src/app/appModules/select-address/select-address.component';
import { AddressService } from 'src/app/appServices/address.service';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { ToasterService } from 'src/app/core/toaster.service';
import { GoogleAnalyticsService } from 'src/app/appServices/google-analytics.service';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent extends HandleSubscriptionsComponent implements OnInit {
  @Input() data;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onUpdate = new EventEmitter<string>();

  constructor(
    private addressService: AddressService,
    public dialog: MatDialog,
    private toasterMessage: ToasterService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private _du:DesignUtilityServices
  ) {
    super();
   }

  ngOnInit(): void {
  }




  onEdit(): void {
    const dialogRef = this.dialog.open(SelectAddressComponent, {
      data: this.data,
      maxWidth: 'unset'
    });
    this.handleSubscription(dialogRef.afterClosed(),
      (result) => {
        if (result) {
          this.onUpdate.emit('update');
          this.googleAnalyticsService.eventEmitter("address", "address_edited", "Address has been edited", "click", 5);
        }
      }
    );
  }

  onDelete(): void {
    this.handleSubscription(this._du.openPrompt('Do you want to delete this address?'),
      (res) => {
        if(res){
          this.handleSubscription(this.addressService.deleteAddress(this.data.id),
            (res) => {
              this.addressService.setDefaultAddress();
              this.toasterMessage.successMessage('Address is successfully removed.');
              this.googleAnalyticsService.eventEmitter("address", "address_deleted", "address has been deleted", "click", 5);
              this.onUpdate.emit('update');
            },
            err => {
              this.toasterMessage.errorMessage('Failed to remove address, Please contact us');
            }
          );
        }
      }
    );
  }


  /**
   * mark default
   */
  makeDefault(address: IAddress): void {
    address.defaultAddress = true;
    this.handleSubscription(this.addressService.updateAddress(address),
      (res) => {
        this.toasterMessage.successMessage('Address is successfully updated as default');
        this.googleAnalyticsService.eventEmitter("address", "made_address_default", "made address default", "click", 5);
        this.onUpdate.emit('update');
      },
      err => {
        this.toasterMessage.errorMessage('Failed, Please contact us');
      }
    );
  }


  /**
   * get saved address
   */
  getAddresses(): void {
    this.handleSubscription(this.addressService.getAddresses(),
      (res) => {
        res.forEach(element => {
          if (element.defaultAddress) {
            this.addressService.defaultAddress.next(element);
          }
        });
      }
    );
  }

}
