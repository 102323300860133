import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectAddressComponent } from 'src/app/appModules/select-address/select-address.component';
import { AddressService } from 'src/app/appServices/address.service';
import { LoginService } from 'src/app/appServices/login.service';
import { GoogleAnalyticsService } from 'src/app/appServices/google-analytics.service';
import { TokenService } from 'src/app/auth/token.service';
import { ToasterService } from 'src/app/core/toaster.service';
import { Router } from '@angular/router';
import { TenentContextService } from 'src/app/auth/tenant-identifier/tenent-context.service';
import { ApiConfigService } from 'src/app/appServices/apiConfigService';
import { PushNotificationsService } from 'src/app/appServices/push-notifications.service';
import { UtilityService } from 'src/app/appServices/utility.service';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { RestaurantBasicInfoStorage } from 'src/app/appStorage/restaurant-basic-info.storage';
import { InternationalService } from 'src/app/appServices/international.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends HandleSubscriptionsComponent implements OnInit {
  tenantName: string;
  hide = true;
  fetching: boolean = false;
  mode = 'login';
  otpSent:boolean;
  otpSentForRegister:boolean = false;
  loginWithOpt:boolean = false;
  otp:string;
  maxDate: Date;
  loginForm: FormGroup;
  registerForm: FormGroup;
  forgotForm: FormGroup;
  OtpForm: FormGroup;
  VerifySignupForm: FormGroup;
  validator = {
    mobile: '^[1-9][0-9]{9}$',
    email: '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
    otp: '^[0-9]*$'
  };
  prod = this.apiConfig.envProd;
  private path: string = '../../../assets/images/icons/';
  basicInfo: any;
  selectedCountry:any;
  usernameType: any = '';

  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private infoStorage: RestaurantBasicInfoStorage,
    private tokenService: TokenService,
    private toasterMessage: ToasterService,
    private tenantContextService: TenentContextService,
    private addressService: AddressService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public dialog: MatDialog,
    private router: Router,
    private apiConfig: ApiConfigService,
    private _pushNotificationsService: PushNotificationsService,
    private _utilityService: UtilityService,
    private _internationalService: InternationalService
  ) {
    super();
    const currentYear = new Date().getFullYear();
    this.maxDate = new Date(currentYear - 1, 12, 31);
    this._utilityService.addSVGIconToMatRegistry(
      'facebook48',
      `${this.path}facebook-48.svg`
    );
    this._utilityService.addSVGIconToMatRegistry(
      'google48',
      `${this.path}google-48.svg`
    );
  }

  ngOnInit(): void {
    this.subscribeTenantName();
    this.initForms();
    this.getBasicInfo();
  }

  
  /**
   * get basic information
   */
  getBasicInfo(): void {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
          this.initRegisterForm();
          this.getCountries(res?.country);
        }
      }
    );
  }


  
  /**
   * Get Contries
   */
  getCountries(country:string){
    this.handleSubscription(this._internationalService.getCountries(),
      (res) => {
        if (res) {
          this.selectedCountry = res?.find(e => e.countryName === country);
          this.updateValidatorRegister();
          this.updateValidatorLogin();
        }
      }
    );
  }

  
  



  /**
   * Init Forms
   */
  initForms(){
    this.loginForm = this.formBuilder.group({
      userName: [
        '',
        [
          Validators.required,
          Validators.pattern(this.validator[this.usernameType])
        ],
      ],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });

    

    this.forgotForm = this.formBuilder.group({
      mobileNumber: [
        null,
        [
          Validators.required,
          Validators.pattern(
            `(${this.validator.mobile})|(${this.validator.email})`
          ),
        ],
      ],
    });

    this.OtpForm = this.formBuilder.group({
      mobileNumber: [null, [Validators.pattern(`${this.validator.mobile}`)]],
      emailId: [null, [Validators.pattern(`${this.validator.email}`)]],
      otp: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });

    this.VerifySignupForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(4),Validators.pattern(this.validator.otp)]],
    });

  }

  initRegisterForm(){
    const baseForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: [''],
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(this.validator.mobile),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      userRole: [{ id: 2 }],
      emailId: [
        '',
        [Validators.required, Validators.pattern(this.validator.email)],
      ],
      password: ['', [Validators.required, Validators.minLength(4)]],
      terms: [false, Validators.requiredTrue],
    });
  
    this.registerForm = this.basicInfo?.businessType === 'cannabis'
      ? this.formBuilder.group({
          ...baseForm.controls,
          dateOfBirth: ['', Validators.required],
          customerType: ['', Validators.required],
          mmjStateIDNumber: [''],
        })
      : baseForm;
  }

  subscribeTenantName() {
    this.handleSubscription(this.tenantContextService.tenant,
      (res) => {
        this.tenantName = res || null;
      }
    );
  }


  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onLoginReset(): void {
    this.loginForm.reset();
  }

  onRegisterReset(): void {
    this.registerForm.reset();
  }

  onCloseModel(): void {
    this.dialogRef.close();
  }

  goPage(page) {
    this.router.navigate([page]);
    this.onCloseModel();
  }
  // ################################# business logic


  switchLoginMode(){
    this.loginWithOpt = !this.loginWithOpt;
    if(this.loginWithOpt && this.loginForm.get('userName').valid){
      this.sendOtp(true);
    }else if(this.loginWithOpt){
      this.loginWithOpt = false;
      this.toasterMessage.errorMessage('Please enter valid email or mobile.');
    }
  }


 


  /**
   * on Login
   */
  onLoginSubmit(): void {
    this.fetching = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    const payload = {
     userName: this.loginForm.value.userName,
     password: this.loginWithOpt ? this.otp : this.loginForm.value.password,
     userRoleModel: {
       userRole :"customer"
     },
     asOTP:this.loginWithOpt
    } 
    

    this.handleSubscription(this.loginService.login(payload),
      (res) => {
        /** push-notification configuration */
        this._pushNotificationsService.requestPermission();
        this._pushNotificationsService.listen();

        this.tokenService.afterLoginSuccess(
          this.loginForm.value.userName,
          res.token
        );

        this.toasterMessage.successMessage('Welcome back!');

        this.googleAnalyticsService.eventEmitter(
          'login',
          'login',
          this.loginForm.value.userName + ' logged in',
          'click',
          5
        );

        this.fetching = false;
        this.onCloseModel();
        this.dialogRef.close('success');
        this.getAddresses();
      },
      (err) => {
        this.toasterMessage.errorMessage('Login credentials are invalid');
        this.fetching = false;
      }
    );
  }


  /**
   * Convert date to UTC
   * @param date
   * @returns
   */
  convertToUTCString(timestamp) {
    if (timestamp != null) {
      const date = new Date(timestamp);
      const utcString = date.toUTCString();
      const timestp = new Date(utcString).getTime();
      return timestp;
    } else {
      return null;
    }
  }



  /**
   * send otp
   */
  sendOtp(loginMode?:boolean): void {
    this.fetching = true;

    // Validate username only for login mode
    if (loginMode && !this.loginForm.get('userName')?.valid) {
      return;
    }

    // Validate full form for register mode or if username is valid in login mode
    if (!loginMode && this.registerForm.invalid) {
      return;
    }
    
    const payload = {
      mobileNumber: '',
      emailId: '',
    };

    if (loginMode) {
      // Login mode: Use username based on usernameType for mobile or email
      const usernameValue = this.loginForm.get('userName')?.value;
      if (this.usernameType === 'mobile') {
        payload.mobileNumber = usernameValue;
      } else {
        payload.emailId = usernameValue;
      }
    } else {
      // Register mode: Use values directly from registerForm
      payload.mobileNumber = this.registerForm.get('mobileNumber')?.value;
      payload.emailId = this.registerForm.get('emailId')?.value;
    }

    this.handleSubscription(this.loginService.sendOtp(payload),
      (res) => {
        this.toasterMessage.successMessage('OTP Sent');
        this.fetching = false;
        this.otpSentForRegister = true;
      },
      (err) => {
        this.fetching = false;
        console.warn(err);
      }
    );

    
  }


  
  getOtp(otp:string){
    if(otp !=='INVALID'){
      this.otp = otp;
      this.VerifySignupForm.get('otp').patchValue(otp);
      this.loginForm.get('password').patchValue(otp);
    }else{
      this.VerifySignupForm.get('otp').patchValue('');
      this.loginForm.get('password').patchValue('');
    }
  }


  onVerifySignup(){

    let payload = {
      ...this.registerForm.value,
      otp:this.VerifySignupForm.value.otp,
      userOtherInfoModel: {
        customerType: this.registerForm.value.customerType,
        mmjStateIDNumber: this.registerForm.value.mmjStateIDNumber
      },
    }

    delete payload.customerType;
    delete payload.mmjStateIDNumber;

    if (this.registerForm.value.dateOfBirth){
      const dob = this.registerForm.value.dateOfBirth ? this.convertToUTCString(this.registerForm.value.dateOfBirth) : null;
      payload.dateOfBirth = dob;
    }
    
    this.handleSubscription(this.loginService.signup(payload),
      (res) => {
        this.toasterMessage.successMessage('Account created successfully.');
        this.loginForm.controls.userName.patchValue(
          this.registerForm.value.mobileNumber
        );
        this.loginForm.controls.password.patchValue(
          this.registerForm.value.password
        );
        this.googleAnalyticsService.eventEmitter(
          'signup',
          'signup',
          this.registerForm.value.mobileNumber + ' signed up',
          'click',
          5
        );
        this.usernameType='mobile';
        this.updateValidatorLogin();
        this.onLoginSubmit();
        this.fetching = false;
      },
      (err) => {
        this.fetching = false;
        this.toasterMessage.errorMessage('Signup credentials are invalid');
      }
    );
  }

  onForgotSubmit() {
    this.fetching = true;
    this.otpSent = false;
    const emailRegex = RegExp(this.validator.email);
    if (emailRegex.test(this.forgotForm.value.mobileNumber)) {
      // User entered is email
      this.handleSubscription(this.loginService
        .generateEmailOTP(this.forgotForm.value.mobileNumber),
          (res) => {
            this.OTPSendSuccess();
            this.OtpForm.controls.emailId.patchValue(
              this.forgotForm.value.mobileNumber
            );
          },
          (err) => {
            this.fetching = false;
          }
      );
    } else {
      // User entered is mobile number
      // const phoneCode = this.selectedCountry?.phoneCode.replace("+", "");
      // const modifiedMobileNumber = phoneCode + this.forgotForm.value.mobileNumber;

      this.handleSubscription(this.loginService
        .generateOTP(this.forgotForm.value.mobileNumber),
          (res) => {
            this.OTPSendSuccess();
            this.OtpForm.controls.mobileNumber.patchValue(
              this.forgotForm.value.mobileNumber
            );
          },
          (err) => {
            this.fetching = false;
          }
      );
    }
  }


  

  OTPSendSuccess() {
    this.otpSent = true;
    this.toasterMessage.successMessage('OTP sent successfully.');
    this.googleAnalyticsService.eventEmitter(
      'OTP',
      'OTP',
      'OTP Sent',
      'click',
      5
    );
    this.fetching = false;
    this.updateValidatorOtp();
  }

  onOtpSubmit() {
    this.fetching = true;
    this.handleSubscription(this.loginService.updatePassword(this.OtpForm.value),
      (res) => {
        this.mode = 'login';
        this.toasterMessage.successMessage('Password changed successfully.');
        this.googleAnalyticsService.eventEmitter(
          'forgot_password',
          'forgot_password',
          'Password changed successfully',
          'click',
          5
        );
        this.fetching = false;
      },
      (err) => {
        this.fetching = false;
        this.googleAnalyticsService.eventEmitter(
          'forgot_password',
          'forgot_password',
          'Password change failed',
          'click',
          7
        );
        //    this.toasterMessage.errorMessage(err.error.details[0]);
      }
    );
  }

  /**
   * get addresses
   */
  getAddresses(): void {
    this.handleSubscription(this.addressService.getAddresses(),
      (res) => {
        if (res.length === 0) {
          this.openAddAddressBox();
        }
      }
    );
  }

  // Add Address
  openAddAddressBox(): void {
    const dialogRef = this.dialog.open(SelectAddressComponent, {
      maxWidth: 'unset',
      data: {
        noOfAddresses: 0,
      },
    });
    this.handleSubscription(dialogRef.afterClosed(),
      (result) => {}
    );
  }

  // ####### helper methods
  getTimestampFromDate(date: Date): number {
    return date.getTime();
  }


  
  /**
   * Change Input Type based on username (mobile or email)
   */
  changeInputType() {
    if (!this.loginForm.value.userName.length) {
      this.usernameType = null;
    } else if (typeof +this.loginForm.value.userName === "number" && !isNaN(+this.loginForm.value.userName)) {
      this.usernameType = 'mobile';
    } else {
      this.usernameType = 'email';
    }
    this.updateValidatorLogin();
  }


  /**
   * Change Input Type based on username (mobile or email)
   */
  changeInputType2() {
    if (!this.forgotForm.value.mobileNumber.length) {
      this.usernameType = null;
    } else if (typeof +this.forgotForm.value.mobileNumber === "number" && !isNaN(+this.forgotForm.value.mobileNumber)) {
      this.usernameType = 'mobile';
    } else {
      this.usernameType = 'email';
    }
    this.updateValidatorForgot();
  }
  

  /**
   * Update Validator Medical
   */
  updateValidatorMedical() {
    if (this.registerForm.get('customerType').value==='Medical') {
      this.registerForm.get('mmjStateIDNumber').setValidators(
        [ Validators.required ]
      );

    } else{
      this.registerForm.get('mmjStateIDNumber').setValidators(
        [ ]
      );
    }
    this.registerForm.get('mmjStateIDNumber').updateValueAndValidity();
  }

  
  /**
   * Update Validator Register
   */
  updateValidatorRegister() {
    this.registerForm.get('mobileNumber').setValidators(
      [ 
        Validators.required,
        Validators.pattern(`^((\\+91-?)|0)?([0-9]{${+this.selectedCountry?.mobileNumberLength}})$`),
        Validators.minLength(+this.selectedCountry?.mobileNumberLength),
        Validators.maxLength(+this.selectedCountry?.mobileNumberLength)
      ]
    );

    this.registerForm.get('mobileNumber').updateValueAndValidity();
  }
  
  /**
   * Update Validator for login
   */
  updateValidatorLogin() {
    
    if (this.usernameType==='mobile') {
      this.loginForm.get('userName').setValidators(
        [ 
          Validators.required,
          Validators.pattern(`^((\\+91-?)|0)?([0-9]{${+this.selectedCountry?.mobileNumberLength}})$`),
          Validators.minLength(+this.selectedCountry?.mobileNumberLength),
          Validators.maxLength(+this.selectedCountry?.mobileNumberLength)
        ]
      );

    } else if(this.usernameType==='email'){
      this.loginForm.get('userName').setValidators(
        [ 
          Validators.required,
          Validators.pattern(this.validator[this.usernameType]),
          Validators.minLength(6)
        ]
      );

    } else {
      this.loginForm.get('userName').setValidators([Validators.required, Validators.minLength(6)]);
    }
    this.loginForm.get('userName').updateValueAndValidity();
  }
  
  /**
   * Update Validator for Forgot
   */
  updateValidatorForgot() {
    
    if (this.usernameType==='mobile') {
      this.forgotForm.get('mobileNumber').setValidators(
        [ 
          Validators.required,
          Validators.pattern(`^((\\+91-?)|0)?([0-9]{${+this.selectedCountry?.mobileNumberLength}})$`),
          Validators.minLength(+this.selectedCountry?.mobileNumberLength),
          Validators.maxLength(+this.selectedCountry?.mobileNumberLength)
        ]
      );

    } else if(this.usernameType==='email'){
      this.forgotForm.get('mobileNumber').setValidators(
        [ 
          Validators.required,
          Validators.pattern(this.validator[this.usernameType]),
          Validators.minLength(6)
        ]
      );

    } else {
      this.forgotForm.get('mobileNumber').setValidators([Validators.required, Validators.minLength(6)]);
    }
    this.forgotForm.get('mobileNumber').updateValueAndValidity();
  }
  

  /**
   * Update Validator for OTP
   */
  updateValidatorOtp() {
    
    if (this.usernameType==='mobile') {
      this.OtpForm.get('mobileNumber').setValidators(
        [ 
          Validators.required,
          Validators.pattern(`^((\\+91-?)|0)?([0-9]{${+this.selectedCountry?.mobileNumberLength}})$`),
          Validators.minLength(+this.selectedCountry?.mobileNumberLength),
          Validators.maxLength(+this.selectedCountry?.mobileNumberLength)
        ]
      );

    } else if(this.usernameType==='email'){
      this.OtpForm.get('mobileNumber').setValidators(
        [ 
          Validators.required,
          Validators.pattern(this.validator[this.usernameType]),
          Validators.minLength(6)
        ]
      );

    } else {
      this.OtpForm.get('mobileNumber').setValidators([Validators.required, Validators.minLength(6)]);
    }
    this.OtpForm.get('mobileNumber').updateValueAndValidity();
  }

  
}
