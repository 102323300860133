import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HostListener, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
// import { ApiConfigService } from 'src/app/appServices/apiConfigService';
import { HelperService } from 'src/app/appServices/helper.service';
import { UtilityService } from 'src/app/appServices/utility.service';
import { TenantInitService } from './tenant-init.service';
import { NotificationBarService } from './notification-bar.service';
import { PushNotificationsService } from '../../appServices/push-notifications.service';
import { LoginService } from 'src/app/appServices/login.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  //   api = this.apiConfig.getBaseUrl + 'banner';

  isBrowser: boolean;
  isServer: boolean;

  get restaurantInfo() {
    return this._tenentInitService.restaurantInfo;
  }

  get showNotificationBar() {
    return this._notificationBarService.NotificationBarStatus;
  }

  constructor(
    // private apiConfig: ApiConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    private _utilityService: UtilityService,
    private _tenentInitService: TenantInitService,
    private _notificationBarService: NotificationBarService,
    private _pushNotificationsService: PushNotificationsService,
    private loginService: LoginService
  ) { }

  appInitializer() {
    // Disable Push Notification Feature

    this.subscirbeIsLoggedIn();
    this.isBrowser = isPlatformBrowser(this._utilityService.getPlatformId());
    this.isServer = isPlatformServer(this._utilityService.getPlatformId());
    this.routerEventInit();

    this.helperService.webView.next(this.findWebview());

    this._tenentInitService.init();
    this._notificationBarService.init();
  }

  /**
   * subscribe user is logged in
   */
  subscirbeIsLoggedIn() {
    this.loginService.loggedIn.subscribe(res => {
      if (res) {
        this._pushNotificationsService.requestPermission();
        this._pushNotificationsService.listen();
      }
    })
  }

  /**
   * Router Events
   */
  routerEventInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        setTimeout(() => {
          if (this.isBrowser) {
            document.getElementById('wrapper').style.top = null;
            document
              .getElementsByTagName('html')[0]
              .classList.remove('hideScroll');
            window.scrollTo(0, 0);
          }
        }, 200);
      });
  }

  /**
   * detect webview
   */
  findWebview(): boolean {
    if (this.isBrowser) {
      const isAndroidWebView = navigator.userAgent.includes('wv');
      if (isAndroidWebView) {
        return true;
      }
      const isIosWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(
        navigator.userAgent
      );
      if (isIosWebView) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check Scroll Position
   */
  topPosToStartShowing = 200;
  @HostListener('window:scroll')
  checkScroll(): boolean {
    if (this.isBrowser) {
      const scrollPosition =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;

      if (scrollPosition >= this.topPosToStartShowing) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Go to Top
   */
  gotoTop() {
    if (this.isBrowser) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
