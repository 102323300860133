import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  api = this.apiConfig.getBaseUrl;

  constructor(
    private apiConfig: ApiConfigService) { }

  /**
   * get all stores info
   */
  getAllStores(): Observable<any> {
    return this.apiConfig.get(this.api + "store");
  }

  /**
   * Get Stripe account by store id
   * @param storeId 
   * @returns 
   */
  getStripeAcByStoreId(storeId): Observable<any> {
    return this.apiConfig.get(this.api + "store/stripe/" +storeId);
  }

}
