import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { AnalyticSettings } from "../appModels/IAnalyticSettings";
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class AnalyticSettingsService {

  api = this.apiConfig.getBaseUrl+ 'analytic-settings';

  constructor(
    private apiConfig: ApiConfigService) {
  }

  getAnalyticSettings(): Observable<any> {
    return this.apiConfig.get(this.api);
  }

  // updateAnalyticSettings(analyticSettings: AnalyticSettings): Observable<any> {
  //   return this.apiConfig.put(this.api, analyticSettings, {withCredentials: true});
  // }
}
