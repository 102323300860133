import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICartDetails } from '../appModels/ICartDetails';
import * as _ from 'underscore';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  api = this.apiConfig.getBaseUrl+ 'cart-details';

  loading = new BehaviorSubject(false);
  cart = new BehaviorSubject(null);

  constructor(
    private apiConfig: ApiConfigService) {
  }

  

  getCart(): void {
    this.loading.next(true);
    this.apiConfig.get(this.api, { withCredentials: true }).subscribe((res: any[]) => {
      this.loading.next(false);
      res.forEach(item => {
        this.calculatePerItemPrice(item);
      });
      this.cart.next(res);
    },
      err => {
        this.loading.next(false);
        this.cart.next(null);
      });
  }
  removeItem(item: ICartDetails): void {
    this.loading.next(true);
    this.apiConfig.delete(this.api + '/' + item.id, { withCredentials: true }).subscribe(res => {
      this.getCart();
    });
  }

  addToCart(item: ICartDetails): void {
    this.loading.next(true);
    this.apiConfig.post(this.api, item, { withCredentials: true }).subscribe(res => {
      this.getCart();
    });
  }

  getCartDetailById(cartId): ICartDetails {
    if(!this.cart.value){
      return null;
    }
    const cartList = this.cart.value.filter(e => {
      return e.id === cartId;
    });
    if (cartList.length > 0) {
      return _.clone(cartList[0]);
    }
    return null;
  }


  // ######### helper methods

  /**
   * calculate per item price
   * @param item 
   */
  calculatePerItemPrice(item): void {
    let price = item.productModel.basePrice;
    if (item.productModel.discount) {
      price = item.productModel.discount.discount;
    }
    item.cartDetailsVariantModelList.forEach(variant => {
      price = price + variant.price;
    });
    item.cartDetailsAddOnModelList.forEach(addOn => {
      price = price + addOn.addOnModel.addOnPrice;
    });
    price = price * item.quantity;
    item.price = price;
  }

  /**
   * get total price of cart
   * @param cartDetails 
   */
  totalPriceOfCart(): number {
    let total = 0;
    if (this.cart.value) {
      this.cart.value.forEach(element => {
        if(element?.loyaltyPointsSpent > 0) {
          total = total + 0;
        } else {
          total = total + element.price;
        }
      });
    }
    return total;
  }


  /**
   * total loyaltyPointsSpend
   * @returns
   */
  totalLoyaltyPointsSpent(): any {
    let totalPointsSpent = 0;
    if (this.cart.value) {
      this.cart.value.forEach(element => {
        if(element?.loyaltyPointsSpent > 0) {
          totalPointsSpent = totalPointsSpent + element?.loyaltyPointsSpent;
        } 
      });
    }
    return totalPointsSpent;
  }

}
