<div class="notification-bar outline-primary background">
    <div class="content primary">
        <mat-icon class="info">
            info
        </mat-icon>
        Please update your account info.
    </div>
    <div class="action">
        <button mat-flat-button color="primary"class="update-btn" (click)="openProfileDialog()">Update now</button>
        <button mat-icon-button class="close-btn foreground opacity-8"
            (click)="onCloseNotificationBar()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>