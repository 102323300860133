import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { CannabisSettingsService } from 'src/app/appServices/biotrack/cannabis-settings.service';
import { StoresService } from 'src/app/appServices/stores.service';
import { DeliveryTypeStorage } from 'src/app/appStorage/delivery-type.storage';
import { RestaurantBasicInfoStorage } from 'src/app/appStorage/restaurant-basic-info.storage';
import { CheckoutHelperService } from 'src/app/pages/checkout/checkout-helper.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-select-store',
  templateUrl: './select-store.component.html',
  styleUrls: ['./select-store.component.scss']
})
export class SelectStoreComponent extends HandleSubscriptionsComponent implements OnInit {
  S3_URL = environment.S3_URL;
  stores: IStore[];
  basicInfo: any;
  @Output()
  multipleStore = new EventEmitter();


  @Output()
  onSelectedStore = new EventEmitter();
  private dialogRef = null;
  constructor(
    private storesService: StoresService,
    private checkoutHelperService: CheckoutHelperService,
    private infoStorage: RestaurantBasicInfoStorage,
    private injector: Injector,
    private deliveryTypeStorage: DeliveryTypeStorage,
    private cannabisSettingsService: CannabisSettingsService,
  ) {
    super();
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.getAllStores();
  }

  ngOnInit(): void {


    this.getBasicInfo();
  }

  /**
   * get all stores info
   */
  getAllStores() {
    this.handleSubscription(this.storesService.getAllStores(),
      (res) => {
        this.stores = res;
        if(this.stores.length === 1 && this.stores[0].active) {
          this.onSelectStore(this.stores[0]);
        }
      }
    );
  }

  
  /**
   * on select store
   * @param store 
   */
  onSelectStore(store: IStore) {
    this.checkoutHelperService.selectedStore.next(store);
    this.deliveryTypeStorage.getDeliveryTypesFromAPI(store.id);
    this.getTaxIncludedSetting(store.id);
    this.multipleStore.emit(this.stores.length > 1 ? true : false);
    this.onCloseModel();
  }



  onCloseModel(): void {
    this.dialogRef?.close();
  }

  /**
 * get basic information
 */
  getBasicInfo(): void {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  /**
   * get tax included value
   * @param storeId 
   */
  getTaxIncludedSetting(storeId: number) {
    if (this.basicInfo.businessType == 'cannabis') {
      this.cannabisSettingsService.getSettingsByStore(storeId).subscribe(res => {
        if (res) {
          const taxIncludedSetting = res.find(setting => setting.name === 'taxIncluded');
          if (taxIncludedSetting) {
            this.checkoutHelperService.isTaxIncluded.next(+taxIncludedSetting.value === 1);
          }
        }
      });
    }
  }

}
