import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtpFieldComponent } from './otp-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [OtpFieldComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [OtpFieldComponent],
})
export class OtpFieldModule {}
