import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IProduct } from 'src/app/appModels/IProduct';
import { IStore } from 'src/app/appModels/IStore';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { RestaurantBasicInfoStorage } from 'src/app/appStorage/restaurant-basic-info.storage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trending-carousel',
  templateUrl: './trending-carousel.component.html',
  styleUrls: ['./trending-carousel.component.scss'],
  providers: []
})
export class TrendingCarouselComponent extends HandleSubscriptionsComponent implements OnInit {

  S3_URL = environment.S3_URL;
  //------------------------------------------// 
  // Properties Area                          //
  //------------------------------------------//
  @Input() title: string;
  @Input() categoryId: number;
  @Input() notInclude: number;
  carousel: any; // For Swiper Carousel
  theme; // Global Theme

  products: IProduct[];
  swiperConfig = catSwiperConfig;

  basicInfo: any;

  selectedStore: IStore;

  constructor(
    private _du: DesignUtilityServices,
    private infoStorage: RestaurantBasicInfoStorage,
    private router: Router
  ) {
    super();
    this.handleSubscription(this._du.theme,
      (res) => {
        this.theme = res
      }
    );
  }


  ngOnInit(): void {
    this.getBasicInfo();
    this.getByCategory();
  }


  getByCategory() {
    if (this.categoryId) {
      // this.products = this.menuStorage.getMenu(this.categoryId);
    }
  }

  gotToProduct(item): void {
    this.router.navigate(['/product', item.id]);
  }

  /**
* get basic info
*/
  getBasicInfo(): void {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

}


export const catSwiperConfig = {
  slidesPerView: 5.2,
  spaceBetween: 20,
  //freeMode: true,
  pagination: {
    clickable: true,
    bulletActiveClass: 'bg-primary'
  },
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.5,
      spaceBetween: 20
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2.5,
      spaceBetween: 20
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 4.3,
      spaceBetween: 20
    }
  }
}
