import { isPlatformBrowser } from '@angular/common';
import { Directive, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
declare var $: any;
@Directive({
  selector: '[appGoNext]'
})
export class GoNextDirective {
  @Input('appGoNext') goNextId: string;
  isBrowser: boolean;
  
  constructor(@Inject(PLATFORM_ID) platformId: Object){
    this.isBrowser = isPlatformBrowser(platformId);
  }

  @HostListener('click')
  click() {
    if(this.isBrowser) {
      setTimeout(() => {
        document.getElementById(this.goNextId).scrollIntoView({behavior: "smooth"});
      }, 1000);
    }
  }
}
