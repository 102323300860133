import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { SelectAddressComponent } from './select-address.component';
import { MatModule } from '../mat-module';

@NgModule({
  declarations: [
    SelectAddressComponent
  ],
  imports: [
    CommonModule,
    MatModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyChudOPFhRGf5uCi8avU0j9XbmRM4R7Ebo',
      libraries: ['places', 'geometry']
    }),
    AgmDirectionModule
  ],
  exports: [
    SelectAddressComponent
  ]
})
export class SelectAddressModule { }
