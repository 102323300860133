import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../includes/header/header.component';
import { FooterComponent } from '../includes/footer/footer.component';
import { LoginComponent } from '../includes/login/login.component';
import { ChangePasswordComponent } from '../includes/change-password/change-password.component';
import { OtpFieldModule } from '../includes/otp-field/otp-field.module';
import { ThemeSwitchComponent } from '../includes/theme-switch/theme-switch.component';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { MatModule } from './mat-module';
import { DesignUtilitiesModule } from './design-utilities.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SelectStoreComponent } from 'src/app/includes/select-store/select-store.component';
import {TrendingCarouselComponent } from 'src/app/includes/trending-carousel/trending-carousel.component';


@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        ChangePasswordComponent,
        ThemeSwitchComponent,
        SelectStoreComponent,
        TrendingCarouselComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        DesignUtilitiesModule,
        LoadingBarRouterModule,
        LoadingBarHttpClientModule,
        MatModule,
        OtpFieldModule,
        NgxSkeletonLoaderModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        LoginComponent,
        ChangePasswordComponent,
        DesignUtilitiesModule,
        LoadingBarRouterModule,
        ThemeSwitchComponent,
        MatModule,
        OtpFieldModule,
        NgxSkeletonLoaderModule,
        SelectStoreComponent,
        TrendingCarouselComponent
    ]
})
export class CoreModule {

}
