import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginService } from 'src/app/appServices/login.service';
import { ToasterService } from 'src/app/core/toaster.service';
import { GoogleAnalyticsService } from 'src/app/appServices/google-analytics.service';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends HandleSubscriptionsComponent implements OnInit {
  hide = true;
  hide2 = true;
  fetching:boolean = false;
  Form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private toasterMessage: ToasterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      currentPassword: ['', [Validators.required]],
      updatedPassword: ['', Validators.required],
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.Form.controls; }


  onReset(): void {
    this.Form.reset();
  }

  onCloseModel(): void {
    this.dialogRef.close();
  }

  // ################################# business logic

  /**
   * on Change Passwoad
   */
  onSubmit() {
    this.fetching = true;
    // stop here if form is invalid
    if (this.Form.invalid) {
      return;
    }
    this.handleSubscription(this.loginService.updatePasswordWithOutOTP(this.Form.value),
      (res) => {
        this.toasterMessage.successMessage('Password updated successfully.');
        this.googleAnalyticsService.eventEmitter("forgot_password", "forgot_password", "Password changed successfully", "click", 5);
        this.fetching = false;
        this.onCloseModel();
      }, err => {
        this.fetching = false;
        this.toasterMessage.errorMessage(err.error.details[0]);
      }
    );
  }

}
