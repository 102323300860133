import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    data: {
      title: 'Home',
      description: 'Description on page',
    }
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    data: {
      title: 'Profile',
      description: 'Description on page',
    }
  },
  {
    path: 'bag',
    loadChildren: () => import('./pages/bag/bag.module').then(m => m.BagModule),
    data: {
      title: 'Bag',
      description: 'Description on page',
    }
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/appcheckout.module').then(m => m.AppCheckoutModule),
    data: {
      title: 'Checkout',
      description: 'Description on page',
    }
  },
  {
    path: "privacy",
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule),
    data: {
      title: 'Privacy Policy',
      description: 'Description on page',
    }
  },
  {
    path: "terms",
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule),
    data: {
      title: 'Terms of Use',
      description: 'Description on page',
    }
  },
  {
    path: "cms/:title",
    loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule),
    data: {
      title: 'Cms',
      description: 'Description on page',
    }
  },
  {
    path: 'product/:id',
    loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule),
    data: {
      title: 'Product',
      description: 'Description on page',
    }
  },
  {
    path: 'product/:id/label',
    loadChildren: () => import('./pages/product/label/label.module').then(m => m.LabelModule),
    data: {
      title: 'Product',
      description: 'Description on page',
    }
  },
  {
    path: 'thanks',
    loadChildren: () => import('./pages/thanks/thanks.module').then(m => m.ThanksModule),
    data: {
      title: 'Thanks',
      description: 'Description on page',
    }
  },
  {
    path: 'flyers/:flyerId/:mobileNumber',
    loadChildren: () => import('./pages/flyers/flyers.module').then(m => m.FlyersModule),
    data: {
      title: 'Flyer',
      description: 'Flyer',
    }
  },
  {
    path: 'notify-restro/:id',
    loadChildren: () => import('./pages/notify-restro/notify-restro.module').then(m => m.NotifyRestroModule),
    data: {
      title: 'Notify Restro',
      description: 'Description on page',
    }
  },
  {
    path: 'receipt/:id',
    loadChildren: () => import('./pages/receipt/receipt.module').then(m => m.ReceiptModule),
    data: {
      title: 'Receipt',
      description: 'Description on page',
    }
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('./pages/leaderboard/leaderboard.module').then(m => m.LeaderboardModule),
    data: {
      title: 'Leaderboard',
      description: 'Description on page',
    }
  },
  {
    path: 'unsubscribe/:type',
    loadChildren: () => import('./pages/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule),
    data: {
      title: 'Unsubscribe',
      description: 'Unsubscribe notifications',
    }
  },

  // {
  //   path : 'page-not-found',
  //   loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
  //   data: {
  //     title: '',
  //     description: '',
  //   }
  // },
  // {path: "**", redirectTo: 'page-not-found'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
