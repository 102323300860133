import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModule } from './mat-module';
import { GoNextDirective } from '../appDirectives/go-next.directive';
import { scrollToActiveDirective } from '../appDirectives/scroll-to-active.directive';
import { HttpClientModule } from '@angular/common/http';
import { NoOrdersComponent } from '../pages/no-orders/no-orders.component';
import { PromptComponent } from '../includes/popups/prompt/prompt.component';
import { JoinLeaderboardComponent } from '../includes/popups/join-leaderboard/join-leaderboard.component';
import { AgeProofPromptComponent } from '../includes/popups/age-proof-prompt/age-proof-prompt.component';
import { NotificationBarComponent } from '../includes/notification-bar/notification-bar.component';
import { UpdateProfileComponent } from '../includes/popups/update-profile/update-profile.component';
import { PaymentSuccessComponent } from '../includes/popups/payment-success/payment-success.component';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';
import { StripeCardPaymentComponent } from '../includes/payment-gateway/stripe-card-payment/stripe-card-payment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { CreditCardDirectivesModule } from 'angular-cc-library';

@NgModule({
  declarations: [
    GoNextDirective,
    NoOrdersComponent,
    PromptComponent,
    JoinLeaderboardComponent,
    AgeProofPromptComponent,
    NotificationBarComponent,
    UpdateProfileComponent,
    scrollToActiveDirective,
    StripeCardPaymentComponent,
    PaymentSuccessComponent
  ],
  imports: [
    CommonModule,
    MatModule,
    HttpClientModule,
    RouterModule,
    SwiperModule,
    NgxStripeModule,
    CreditCardDirectivesModule
  ],
  exports: [
    MatModule,
    GoNextDirective,
    NoOrdersComponent,
    HttpClientModule,
    PromptComponent,
    JoinLeaderboardComponent,
    AgeProofPromptComponent,
    RouterModule,
    SwiperModule,
    NotificationBarComponent,
    UpdateProfileComponent,
    scrollToActiveDirective,
    StripeCardPaymentComponent,
    CreditCardDirectivesModule,
    PaymentSuccessComponent
  ],
  providers: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DesignUtilitiesModule {
}
