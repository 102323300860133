import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressService } from 'src/app/appServices/address.service';
import { RestaurantBasicInfoStorage } from 'src/app/appStorage/restaurant-basic-info.storage';
import { ToasterService } from 'src/app/core/toaster.service';
import { DeliveryTypeStorage } from 'src/app/appStorage/delivery-type.storage';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';


@Component({
  selector: 'app-select-address',
  templateUrl: './select-address.component.html',
  styleUrls: ['./select-address.component.scss'],
})
export class SelectAddressComponent extends HandleSubscriptionsComponent implements OnInit {
  basicInfo: any;
  restaurantVisivilityDistance: number;
  restaurantLocation: any;
  notInVisibility = false;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  fetching: boolean = false;
  isDeliveryEnable: boolean;

  @ViewChild('search')
  public searchElementRef: ElementRef;


  typeList = [
    {
      id: 1,
      addressType: 'Home',
      modifiedDate: 0
    },
    {
      id: 2,
      addressType: 'Work',
      modifiedDate: 0
    },
    {
      id: 3,
      addressType: 'Other',
      modifiedDate: 0
    }
  ];
  addressForm: FormGroup;
  selectedType: any;
  constructor(
    public dialogRef: MatDialogRef<SelectAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private addressService: AddressService,
    private infoStorage: RestaurantBasicInfoStorage,
    private deliveryTypeStorage: DeliveryTypeStorage,
    private toasterMessage: ToasterService
  ) {
    super();
    this.addressForm = this.fb.group({
      id: [],
      address: [],
      addressType: [],
      landmark: [''],
      longitude: [],
      latitude: [],
      note: [],
      pincode: [],
      defaultAddress: [false]
    });
    this.getBasicInfo();
  }

  onNoClick(action: string): void {
    this.dialogRef.close(action);
  }

  updateAddressFormData(): void {
    if (this.data?.id) {
      this.addressForm.patchValue(this.data);
      this.addressForm.controls.addressType.patchValue(this.typeList.find(type => type.id === this.data.addressType.id));
    }
  }

  getDistanceZoneForDelivery(): void {
    this.restaurantVisivilityDistance = this.deliveryTypeStorage.getDeliveryTypeByType('Delivery')?.distanceZone;
    this.isDeliveryEnable = this.deliveryTypeStorage.getDeliveryTypeByType('Delivery')?.active;
  }

  getRestaurantLocation(): void {
    this.restaurantLocation = {
      lat: this.basicInfo.latitude,
      lng: this.basicInfo.longitude
    };
  }

  ngOnInit(): void {
    this.getRestaurantLocation();
    this.getDistanceZoneForDelivery();
    this.updateAddressFormData();
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.updateFormLongitudeAndLatitude(this.longitude, this.latitude);
          this.getAddress(this.latitude, this.longitude);
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation(): void {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (this.data) {
          this.latitude = Number.parseFloat(this.data.latitude);
          this.longitude = Number.parseFloat(this.data.longitude);
        } else {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        }
        this.zoom = 8;
        this.updateFormLongitudeAndLatitude(this.longitude, this.latitude);
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  updateFormLongitudeAndLatitude(longitude, latitude): void {
    this.addressForm.controls.longitude.patchValue(longitude);
    this.addressForm.controls.latitude.patchValue(latitude);
    // const destination = {
    //   lat: latitude,
    //   lng: longitude
    // };
    // if ((Number.parseFloat(this.calculateDistance(this.restaurantLocation, destination))) > this.restaurantVisivilityDistance) {
    //   this.notInVisibility = true;
    // } else {
    //   this.notInVisibility = false;
    // }
  }

  markerDragEnd($event: any): void {
    this.latitude = $event.latLng.lat();
    this.longitude = $event.latLng.lng();
    this.updateFormLongitudeAndLatitude(this.longitude, this.latitude);
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude): void {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.addressForm.controls.address.patchValue(this.address);
        } else {
        }
      } else {
      }

    });
  }

  addAddress(): void {
    this.fetching = true;
    this.handleSubscription(this.addressService.addAddress(this.addressForm.value),
      (res) => {
        this.toasterMessage.successMessage('Address successfully added.');
        this.onNoClick('saved');
        this.fetching = false;
      },
      err => {
        this.fetching = false;
        this.toasterMessage.errorMessage('Failed to add address, Please contact us.');
      }
    );
  }


  updateAddress(): void {
    this.fetching = true;
    this.handleSubscription(this.addressService.updateAddress(this.addressForm.value),
      (res) => {
        this.toasterMessage.successMessage('Address successfully updated.');
        this.onNoClick('updated');
        this.fetching = false;
      },
      err => {
        this.fetching = false;
        this.toasterMessage.errorMessage('Failed to update address, Please contact us.');
      }
    );
  }
  saveAddress(): void {
    if (this.data?.id) {
      this.updateAddress();
    } else {
      this.addAddress();
    }
  }

  // calculateDistance(point1, point2): string {
  //   const p1 = new google.maps.LatLng(
  //     point1.lat,
  //     point1.lng
  //   );
  //   const p2 = new google.maps.LatLng(
  //     point2.lat,
  //     point2.lng
  //   );
  //   return (
  //     google.maps.geometry.spherical.computeDistanceBetween(p1, p2)
  //   ).toFixed(2);
  // }

  /**
   * get basic information
   */
  getBasicInfo(): void {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  onCloseModel(): void {
    this.dialogRef.close();
  }


}
