<header id="header" [ngClass]="{active:menuToggler, dark: theme=='dark-theme'}">
    <mat-toolbar>
        <div class="container row">
            <a routerLink="/" class="logo">
                <!-- <img src="assets/images/light_logo.png" *ngIf="theme === 'dark-theme'" alt="" width="" height="50">
                <img src="assets/images/dark_logo.png" *ngIf="theme != 'dark-theme'" alt="" width="" height="50"> -->
                <ng-container *ngIf="basicInfo.imageLink || basicInfo.darkLogo">
                    <ng-container *ngIf="theme === 'dark-theme'">
                        <img *ngIf="basicInfo.imageLink" [src]="S3_URL+''+basicInfo.bucketName+'/'+basicInfo.imageLink"
                            alt="" width="" height="50">
                        <img *ngIf="!basicInfo.imageLink && basicInfo.darkLogo" [src]="S3_URL+''+basicInfo.bucketName+'/'+basicInfo.darkLogo"
                            alt="" width="" height="50">
                    </ng-container>
                    <ng-container *ngIf="theme !== 'dark-theme'">
                        <img *ngIf="basicInfo.darkLogo" [src]="S3_URL+''+basicInfo.bucketName+'/'+basicInfo.darkLogo"
                            alt="" width="" height="50">
                        <img *ngIf="!basicInfo.darkLogo && basicInfo.imageLink" [src]="S3_URL+''+basicInfo.bucketName+'/'+basicInfo.imageLink"
                            alt="" width="" height="50">
                    </ng-container>
                </ng-container>
            </a>
            <ng-container *ngIf="!basicInfo.imageLink && !basicInfo.darkLogo">
                <h4 routerLink="/" class="logo pointer no-outline">{{basicInfo.restaurantName | uppercase}}</h4>
            </ng-container>
            <!-- <button mat-button>
                <mat-icon class="material-icons-outlined">place</mat-icon> Moscow
            </button> -->
            <span class="spacer"></span>
            <button mat-button routerLink="reward">
                Rewards
            </button>
            <!-- <span class="spacer"></span> -->
            <button mat-button routerLink="leaderboard">
                Leaderboard
            </button>

            <app-theme-switch #tooltip="matTooltip" matTooltip="{{theme=='dark-theme'?'Light': 'Dark'}} Mode"
                [matTooltipPosition]="'below'"></app-theme-switch>
            &nbsp;
            <button mat-button routerLink="bag" class="bag" *ngIf="user && cartCount > 0">
                <div class="flex-item-center">
                    <span class="dhide" *ngIf="getCartTotalPrice() > 0">{{getCartTotalPrice() | currency: basicInfo.currencyCode}}</span>
                    <mat-icon class="material-icons-outlined" [matBadge]="cartCount" matBadgePosition="after"
                        matBadgeColor="primary">shopping_bag</mat-icon>
                </div>
            </button>

            <button mat-button [matMenuTriggerFor]="menu" class="profile"
                [ngClass]="{'login': getCartTotalPrice() === 0}" *ngIf="user">
                <div class="flex-item-center">
                    <mat-icon class="material-icons-outlined">person</mat-icon>
                    <span class="ml-1 dhide">{{user.firstName}}</span>
                </div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item routerLink="profile">
                    <mat-icon>person</mat-icon>
                    <span>Profile</span>
                </button>
                <!-- <button mat-menu-item routerLink="profile">
                    <mat-icon>shopping_bag</mat-icon>
                    <span>Orders</span>
                </button>
                <button mat-menu-item routerLink="profile">
                    <mat-icon>shopping_cart</mat-icon>
                    <span>Address</span>
                </button> -->
                <button mat-menu-item color="warn" (click)="logout('Are you sure you want to logout?')">
                    <mat-icon>power_settings_new</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>
            <button mat-button *ngIf="!user" class="profile login" (click)="openLoginModel()">
                <div class="flex-item-center">
                    <mat-icon class="material-icons-outlined">person</mat-icon>
                    <span class="ml-1 dhide">Login</span>
                </div>
            </button>
            <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item (click)="openLoginModel()">
                    <mat-icon>lock</mat-icon>
                    <span>Login</span>
                </button>
                <button mat-menu-item routerLink="cms">
                    <mat-icon>text_rotation_none</mat-icon>
                    <span>Content Page</span>
                </button>
                <button mat-menu-item routerLink="product/burger">
                    <mat-icon>wallpaper</mat-icon>
                    <span>Product Detail</span>
                </button>
                <button mat-menu-item routerLink="profile">
                    <mat-icon>person</mat-icon>
                    <span>Profile</span>
                </button>
                <button mat-menu-item routerLink="bag">
                    <mat-icon>shopping_bag</mat-icon>
                    <span>Bag</span>
                </button>
                <button mat-menu-item routerLink="checkout">
                    <mat-icon>shopping_cart</mat-icon>
                    <span>Checkout</span>
                </button>
                <button mat-menu-item routerLink="notify-restro">
                    <mat-icon>shopping_cart</mat-icon>
                    <span>notify-restro</span>
                </button>
                <button mat-menu-item routerLink="receipt/18">
                    <mat-icon>shopping_cart</mat-icon>
                    <span>receipt</span>
                </button>
            </mat-menu> -->
            <!-- <button mat-icon-button>
                <mat-icon>menu</mat-icon>
            </button> -->

            <!-- <span class="menu-toggler" (click)="onMenuToggle()">
                <span class="line"></span>
            </span> -->
        </div>
    </mat-toolbar>
</header>
<!-- <header id="header">
     <div class="container row">
         <div class="left">
            <a routerLink="/">
                <img src="/assets/images/remove/logo.jpg" alt="" width="" height="">
            </a>
            <span class="location">
                <mat-icon>place</mat-icon> Moscow
            </span>
        </div>
         <div class="right">
             <ul class="topList">
                 <li class="bag">
                    <a href="#">
                        $20.25 <mat-icon>shopping_bag</mat-icon>
                    </a>
                </li>
                 <li>
                    <a href="#">
                        <mat-icon>person_outline</mat-icon> Daniel S.
                    </a>
                </li>
             </ul>
            <span class="menu-toggler" (click)="onMenuToggle()">
                <span class="line"></span>
            </span>
         </div>
     </div>
 </header> -->