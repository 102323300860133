import { Injectable } from '@angular/core';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiConfigService } from './apiConfigService';
import {
  Platform
} from '@angular/cdk/platform';
@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  message: any = null;
  api = this.apiConfig.getBaseUrl;

  constructor(
    private apiConfig: ApiConfigService,
    public platform: Platform) {
  }

  /**
   * request permissing
   */
  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.subscribeTokenToTopic(currentToken).subscribe((responseData) => { });
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.message = payload;
    });
  }

  /**
   * subscribe token
   * @param tokenData 
   * @returns 
   */
  private subscribeTokenToTopic(tokenData): Observable<any> {
    const payload = {
      "deviceType": this.platform.ANDROID ? 'android' : this.platform.IOS ? 'ios' : 'browser',
      "deviceName": window.navigator.userAgent,
      "fcmToken": tokenData
    }
    return this.apiConfig.put(`${this.api}user/device`, payload);
  }
}
