<footer class="bg-app">
  <div class="container">
    <div class="row first">
      <a routerLink="/" class="logo">
        <ng-container *ngIf="basicInfo.imageLink || basicInfo.darkLogo">
          <ng-container *ngIf="theme === 'dark-theme'">
            <img
              *ngIf="basicInfo.imageLink"
              [src]="
                S3_URL + '' + basicInfo.bucketName + '/' + basicInfo.imageLink
              "
              alt=""
              width=""
              height="60"
            />
            <img
              *ngIf="!basicInfo.imageLink && basicInfo.darkLogo"
              [src]="
                S3_URL + '' + basicInfo.bucketName + '/' + basicInfo.darkLogo
              "
              alt=""
              width=""
              height="60"
            />
          </ng-container>
          <ng-container *ngIf="theme !== 'dark-theme'">
            <img
              *ngIf="basicInfo.darkLogo"
              [src]="
                S3_URL + '' + basicInfo.bucketName + '/' + basicInfo.darkLogo
              "
              alt=""
              width=""
              height="60"
            />
            <img
              *ngIf="!basicInfo.darkLogo && basicInfo.imageLink"
              [src]="
                S3_URL + '' + basicInfo.bucketName + '/' + basicInfo.imageLink
              "
              alt=""
              width=""
              height="60"
            />
          </ng-container>
        </ng-container>
      </a>
      <ng-container *ngIf="!basicInfo.imageLink && !basicInfo.darkLogo">
        <h4 routerLink="/" class="logo pointer no-outline">
          {{ basicInfo.restaurantName | uppercase }}
        </h4>
      </ng-container>
      <div class="social">
        <ng-container *ngFor="let item of social">
          <a
            *ngIf="item.url"
            [href]="item.url"
            target="_blank"
            rel="noopener noreferrer"
            class="foreground"
          >
            <i class="{{ item.icon }}"></i>
          </a>
        </ng-container>

        <!-- <a [href]="item.url" *ngFor="let item of social">
                    <img src="{{'assets/images/icons/'+item.name+'.svg'}}" alt="{{item.name}}">
                </a> -->
      </div>
      <div class="app" *ngIf="!webView">
        <ng-container *ngFor="let item of appStoreLinks">
          <a *ngIf="item.url" href="{{ item.url }}" target="_blank">
            <img src="{{ item.icon }}" alt="" width="130" height="40" />
          </a>
        </ng-container>

        <!-- <a *ngIf="basicInfo.googlePlayLink" href="{{basicInfo.googlePlayLink}}" target="_blank"><img
                        src="assets/images/google-play-button.png" alt="" width="130" height="40"></a>
                <a *ngIf="basicInfo.appleStoreLink" href="{{basicInfo.appleStoreLink}}" target="_blank"><img
                        src="assets/images/app-store-button.png" alt="" width="130" height="40"></a> -->
      </div>
    </div>

    <mat-divider></mat-divider>
    <div class="row last">
      <div class="copyright">
        © <strong>{{ restaurantInfo.restaurantName }}</strong> {{currentYear}}. Powered by
        <a href="https://tastio.com/" target="_blank">Tastio</a>
      </div>
      <ul class="links">
        <li *ngFor="let cms of cmsList">
          <a
            [routerLink]="['/cms', cms.title]"
            target="_blank"
            class="foreground"
            >{{ cms.title }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</footer>
