<div class="add-item-model-container app-bg">
    <a mat-icon-button (click)="onCloseModel()" class="close">
        <mat-icon class="material-icons-outlined">close</mat-icon>
    </a>
    <div class="model-header">
        <h2>Card Information</h2>
    </div>
    <div class="model-body">

        <form novalidate (ngSubmit)="buy()" [formGroup]="stripeTest">

            <mat-form-field appearance="outline" class="input-box full-width">
                <mat-label>Card Holder Name</mat-label>
                <input matInput placeholder="Jane Doe" formControlName="name" class="input-text">
            </mat-form-field>

            <div *ngIf="publicKeyForFirstTranscation">
                <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
            </div>
            <div class="vertical-height"></div>
            <div style="height: 45px;">

                <button mat-raised-button color="primary" type="submit" 
                [disabled]="stripeTest.status=='INVALID' || disablePayButton || fetching" class="full-width">
                        
                        
                    <ng-container *ngIf="!fetching">
                        <span>Pay Securely</span>
                        
                        <span>
                            {{data.totalAmount | currency: basicInfo.currencyCode}}
                        </span>
                    </ng-container>
                    <mat-spinner *ngIf="fetching" diameter="20"></mat-spinner>
                </button>
            </div>
        </form>
    </div>
</div>