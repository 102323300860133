import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LoginService } from 'src/app/appServices/login.service';
import { IUser } from 'src/app/appModels/IUser';
import { ToasterService } from 'src/app/core/toaster.service';
import { TokenService } from 'src/app/auth/token.service';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { NotificationBarService } from 'src/app/core/appInit/notification-bar.service';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent extends HandleSubscriptionsComponent implements OnInit {

  Form: FormGroup;
  fetching:boolean = false;
  user: IUser;
  validator = {
    mobile : '^[1-9][0-9]{9}$',
    email : '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
  }
  constructor(private formBuilder: FormBuilder,
              public dialog: MatDialog,
              private loginService: LoginService,
              private toasterMessage: ToasterService,
              private _notificationBarService: NotificationBarService,
              private tokenService: TokenService,
              private dialogRef: MatDialogRef<UpdateProfileComponent>) { 
                super();
              }

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required, Validators.pattern(this.validator.mobile), Validators.minLength(10), Validators.maxLength(10)]],
      emailId: ['', [Validators.required, Validators.pattern(this.validator.email)]],
    });
    this.getProfileInfo();
  }

  getProfileInfo(){
    this.handleSubscription(this.loginService.loggedIn,
      (res) =>{
        if(res){
          this.user = res;
          this.Form.patchValue(res);
        }
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() { return this.Form.controls; }

  onSubmit() {
    this.fetching = true;
    if (this.Form.invalid) {
      return;
    }

    this.handleSubscription(this.loginService.updateProfile(this.Form.value),
      (res) => {
        this.toasterMessage.successMessage('Profile updated successfully.');
        this.getUserProfile();
        this._notificationBarService.onCloseNotificationBar();
        this.fetching = false;     
        this.dialogRef.close();
      }, err => {
        this.toasterMessage.errorMessage('Profile update failed.');
        this.fetching = false;
      }
    );
  }

  getUserProfile(){
    this.tokenService.getUserProfile();
  }
}
