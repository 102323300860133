import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';
import { IDeliveryType } from '../appModels/IDeliveryType';
import { DeliveryTypeService } from '../appServices/delivery-type.service';

@Injectable({
    providedIn: 'root'
})
export class DeliveryTypeStorage {
    deliveryTypeList = new BehaviorSubject(null);

    constructor(
        private deliveryTypeService: DeliveryTypeService
    ) { }


    setDeliveryTypes(deliveryTypeList: IDeliveryType[]): void {
        this.deliveryTypeList.next(deliveryTypeList);
    }
 
    getDeliveryTypeByType(type: string): IDeliveryType {
        if(!this.deliveryTypeList.value){
            return;
        }
        const index = this.deliveryTypeList.value.findIndex(e => e.orderTypeName.toUpperCase() === type.toUpperCase());
        return _.clone(this.deliveryTypeList.value[index]);
    }

    /**
     * get delivery type from API
     * @param storeId 
     */
    getDeliveryTypesFromAPI(storeId: number): void {
        this.deliveryTypeService.getDeliveryMethods(storeId).subscribe(res => {
            this.setDeliveryTypes(res);
        });
    }
}