import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { RestaurantBasicInfoStorage } from 'src/app/appStorage/restaurant-basic-info.storage';

@Component({
  selector: 'app-age-proof-prompt',
  templateUrl: './age-proof-prompt.component.html',
  styleUrls: ['./age-proof-prompt.component.scss']
})
export class AgeProofPromptComponent  extends HandleSubscriptionsComponent implements OnInit {
  basicInfo: any;
  constructor(
    private infoStorage: RestaurantBasicInfoStorage) {
      super();
      this.getBasicInfo();
    }



  ngOnInit(): void {
  }
  /**
 * get basic info
 */
  getBasicInfo(): void {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  redirectToTastio() {
    window.location.href = 'https://www.tastio.com';
  }

}
