import { Component, OnInit, HostBinding, Inject, PLATFORM_ID } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { isPlatformBrowser } from '@angular/common';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
  selector: 'app-theme-switch',
  templateUrl: './theme-switch.component.html',
  styleUrls: ['./theme-switch.component.scss']
})
export class ThemeSwitchComponent extends HandleSubscriptionsComponent implements OnInit {

  darkThemeEnable: boolean = true;
  theme; // Global Theme
  @HostBinding('class') componentCssClass;

  isBrowser: boolean;
  constructor(
    private _du: DesignUtilityServices,
    public overlayContainer: OverlayContainer,
    @Inject(PLATFORM_ID) platformId: Object) {
      super();
      this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.getThemeValueFromStorage();
    this.setTheme();
    this.handleSubscription(this._du.theme,
      (res) => {
        this.theme = res;
      }
    );
  }

  /**
   * get theme value from storage
   */
  getThemeValueFromStorage(): void {
    if(this.isBrowser) {
      this.darkThemeEnable = localStorage.getItem('darkThemeEnable') ?
      JSON.parse(localStorage.getItem('darkThemeEnable')) : this.darkThemeEnable ;
    }
  
  }
  /**
   * Set Theme
   */
  setTheme() {
    if (this.darkThemeEnable) {
      this.removeThemeClass();
      this.overlayContainer.getContainerElement().classList.add('dark-theme');
      this.componentCssClass = 'dark-theme';
      this._du.theme.next('dark-theme');
    } else {
      this.removeThemeClass();
      this.overlayContainer.getContainerElement().classList.add('light-theme');
      this.componentCssClass = 'light-theme';
      this._du.theme.next('light-theme');
    }
    this.setThemeInLocalStorage();
  }

  /**
   * Remove Theme classes
   */
  removeThemeClass() {
    const classList = this.overlayContainer.getContainerElement().classList;
    for (let index = 0; index < classList.length; index++) {
      const className = classList[index];
      if (className.indexOf('theme') > -1) {
        this.overlayContainer.getContainerElement().classList.remove(className);
      }
    }
  }

  /**
   * set theme in local storage 
   * @param darkTheme 
   */
  setThemeInLocalStorage() {
    if(this.isBrowser){
      localStorage.setItem('darkThemeEnable', JSON.stringify(this.darkThemeEnable));
    }
  }
}
