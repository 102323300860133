import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorMessageService } from './error-message.service';
import { ErrorLoggingService } from './error-logging.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private errorService: ErrorMessageService, private logger: ErrorLoggingService) { }

  handleError(error: Error | HttpErrorResponse) {
    let message;
    let stackTrace;
    if (error instanceof HttpErrorResponse) {
      // Server error
      message = this.errorService.getServerErrorMessage(error);
      stackTrace = this.errorService.getServerStack(error);
      // toastMessage.errorToastWithClose(message);
    } else {
      // Client Error
      message = this.errorService.getClientErrorMessage(error);
      stackTrace = this.errorService.getClientStack(error);
      // toastMessage.errorToastWithClose(message);
    }
    // Always log errors
    this.logger.logError(message, stackTrace);
    console.error(error);
  }
}
