import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThanksHelperService {

  currentOrder = new BehaviorSubject(null);
  
  constructor() { }
}
