import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {


  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry
  ) { }

  getDocumentRefrence() {
    return this.document;
  }

  public getPlatformId() {
    return this.platformId;
  }

  addSVGIconToMatRegistry(name: string, path: string) {
    this.matIconRegistry.addSvgIcon(name, this.setPath(path));
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
