import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class AppStoreLinkService {

  api = this.apiConfig.getBaseUrl + 'app-store';

  constructor(
    private apiConfig: ApiConfigService) { }

  getAppStoreLinks(): Observable<any> {
    return this.apiConfig.get(this.api);
  }

}
