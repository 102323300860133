<div class="form-container">
    <h2>Update Profile</h2>    
    <form [formGroup]="Form" (ngSubmit)="onSubmit()">
        <div class="col" *ngIf="!user.mobileNumber">
            <mat-form-field appearance="outline">
                <mat-label>Mobile number</mat-label>
                <input matInput type="text" placeholder="Ex. 9039907701" formControlName="mobileNumber">
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline">
                <mat-label>Email Id</mat-label>
                <input matInput type="email" formControlName="emailId" placeholder="Ex. abc@mail.com">
            </mat-form-field>
        </div>
        <div class="button-row"> 
            <button mat-raised-button [disabled]="fetching || !Form.valid" color="primary">
                <ng-container *ngIf="!fetching">Update Profile</ng-container>
                <mat-spinner *ngIf="fetching" diameter="20"></mat-spinner>
            </button>
            <button mat-button mat-dialog-close type="button">Cancel</button>
        </div>
    </form>
</div>    