import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from 'src/app/appServices/login.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationBarService {
  
  NotificationBarStatus:boolean = false;
  showNotificationBar = new BehaviorSubject(false);  // For Notification Bar
  
  constructor(
    private loginService: LoginService
  ) { }

  init(){
    this.subscribeNotificationBarStatus();
    this.getNotificationBarStatusFromStorage();
  }

  getNotificationBarStatusFromStorage(){
    const showNotificarionBarOnStorage = JSON.parse(sessionStorage.getItem('showNotificarionBar'));
    if(showNotificarionBarOnStorage!==false){
      this.setNotificationBarStatus();
    }
  }

  setNotificationBarStatus() {
    this.loginService.loggedIn.subscribe(res => {
      if (res) {
        if(!res.emailId || !res.mobileNumber){
          this.showNotificationBar.next(true);
        }
      }
    });
  }

  subscribeNotificationBarStatus(){
    this.showNotificationBar.subscribe(res=>{
      this.NotificationBarStatus = res;
    })
  }

  onCloseNotificationBar(){
    this.showNotificationBar.next(false);
    sessionStorage.setItem('showNotificarionBar', 'false');
  }
 

}
