import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MdePopoverTrigger } from '@material-extended/mde';
import { IUser } from 'src/app/appModels/IUser';
import { CartService } from 'src/app/appServices/cart.service';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { GoogleAnalyticsService } from 'src/app/appServices/google-analytics.service';
import { LoginService } from 'src/app/appServices/login.service';
import { RestaurantBasicInfoStorage } from 'src/app/appStorage/restaurant-basic-info.storage';
import { TokenService } from 'src/app/auth/token.service';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../login/login.component';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends HandleSubscriptionsComponent implements OnInit {
  basicInfo: any;
  user: IUser;
  S3_URL = environment.S3_URL;

  cartCount: number;
  isBrowser: boolean;
  @ViewChild(MdePopoverTrigger, { static: false }) trigger: MdePopoverTrigger;

  closePopover() {
    this.trigger.togglePopover();
  }

  //------------------------------------------//
  // Properties Area                          //
  //------------------------------------------//
  pageActive; // For Which Page is Active
  headerColor;  // For Header Color
  menuToggler; // For Menu Toggle
  navColor; // For Nav Color Theme
  theme; // Global Theme

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _du: DesignUtilityServices,
    private loginService: LoginService,
    private cartService: CartService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private infoStorage: RestaurantBasicInfoStorage,
    private tokenService: TokenService,
    @Inject(PLATFORM_ID) platformId: Object) {
    super();  
    this.isBrowser = isPlatformBrowser(platformId);

    this.handleSubscription(this._du.theme,
      (res) => {
        this.theme = res
      }
    );
  }

  ngOnInit(): void {
    this.getBasicInfo();
    this.getLoggedInUserDetails();
  }


  lastScrollTop: any = 0;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    if(this.isBrowser) {
      var headerElement = document.getElementById('header');
      if (headerElement) {
        var st = window.pageYOffset;
        if (st > this.lastScrollTop) {
          headerElement.classList.remove('open');
        } else if (st < 200) {
          headerElement.classList.remove('open');
        } else {
          headerElement.classList.add('open');
        }
        this.lastScrollTop = st;
        if (st > 600) {
          headerElement.classList.add('sticky');
          this._du.headerColor.next('')
        } else {
          headerElement.classList.remove('sticky');
          if (this.navColor == 'black') {
            this._du.headerColor.next('')
          } else {
            this._du.headerColor.next('white')
          }
        }
      }
    }
 
  }


  onMenuToggle() {
    if(this.isBrowser){
      if (this.menuToggler) { //menu open
        this.menuToggler = false;
        document.getElementsByTagName('html')[0].classList.remove('hideScroll');
        var topValue = $('#wrapper').css('top');
        $('#wrapper').css('top', '');
        // $('.menu-wrapper').scrollTop(0);
        setTimeout(function () {
          $(window).scrollTop(Math.abs(parseInt(topValue)));
        }, 30);
      }
      else {//menu close
        this.menuToggler = true;
        var scrollTop = $(window).scrollTop();
        $('#wrapper').css('top', -scrollTop);
        document.getElementsByTagName('html')[0].classList.add('hideScroll');
      }
    }

  }

  openLoginModel(): void {
    const dialogRef = this.dialog.open(LoginComponent);
    this.handleSubscription(dialogRef.afterClosed(),
      (result) => {
      }
    );
  }


  // ######## business
  /**
   * get logged in user details
   */
  getLoggedInUserDetails(): void {
    this.handleSubscription(this.loginService.loggedIn,
      (res) => {
        this.user = res;
        if (this.user) {
          this.getCartInfo();
        }
      }
    );
  }

  /**
   * ger cart info
   */
  getCartInfo(): void {
    this.cartService.getCart();
    this.handleSubscription(this.cartService.cart,
      (res) => {
        if (res) {
          this.cartCount = res.length;
        }
      }
    );
  }

  /**
   * get cart total price
   */
  getCartTotalPrice(): number {
    return this.cartService.totalPriceOfCart();
  }


  /**
   * get basic info
   */
  getBasicInfo(): void {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  /**
   * logout
   */
  logout(data): void {
    this.handleSubscription(this._du.openPrompt(data),
      (res) => {
        if (res) {
          this.tokenService.logout();
          this.cartService.cart.next(null);
          this.router.navigate(['/']);
          this.googleAnalyticsService.eventEmitter("logout", "logout", "User has logged out", "click", 5);
        }
      }
    );
  }

}
