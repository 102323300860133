<mat-dialog-content class="mat-typography custom-scrollbar" [ngClass]="{
    'login-wrapper': mode == 'login',
    'register-wrapper': mode == 'register'
  }">
  <a mat-icon-button (click)="onCloseModel()" class="close">
    <mat-icon class="material-icons-outlined">close</mat-icon>
  </a>

  <div class="inner-wrapper landing-wrapper" *ngIf="mode == 'landing'">
    <h1>SINGUP/LOGIN</h1>
    <div class="big-vertical-height"></div>
    <div class="full-width">
      <div class="row btn-wrapper">
        <div class="col col-full">
          <button mat-stroked-button>
            <mat-icon svgIcon="google48"></mat-icon>
            <ng-container *ngIf="!fetching">
              <span>Sign in with Google</span>
            </ng-container>
            <mat-spinner *ngIf="fetching" diameter="20"></mat-spinner>
          </button>
        </div>
      </div>
    </div>
    <div class="full-width">
      <div class="row btn-wrapper">
        <div class="col col-full">
          <button mat-stroked-button>
            <mat-icon svgIcon="facebook48"></mat-icon>
            <ng-container *ngIf="!fetching">
              <span>Sign in with Facebook</span>
            </ng-container>
            <mat-spinner *ngIf="fetching" diameter="20"></mat-spinner>
          </button>
        </div>
      </div>
    </div>
    <div class="full-width">
      <div class="row mb20">
        <div class="col col-full text-center">(OR)</div>
      </div>
    </div>
    <div class="full-width">
      <div class="row btn-wrapper">
        <div class="col col-full">
          <button mat-raised-button color="primary">
            <ng-container *ngIf="!fetching">CREATE AN ACCOUNT</ng-container>
            <mat-spinner *ngIf="fetching" diameter="20"></mat-spinner>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-full text-center">
        Already have an account?
        <span>
          <a class="cursor primary pl10" (click)="mode = 'login'"> Log in</a>
        </span>
      </div>
    </div>
  </div>

  <div class="inner-wrapper" *ngIf="mode == 'login'">
    <h1>
      Log in
      <span>or <a class="cursor primary" (click)="mode = 'register'">create one</a>
      </span>
    </h1>
    <div class="big-vertical-height"></div>
    <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()" autocomplete="off">
      <div class="flex-column full-width form-container">

        <mat-form-field appearance="outline" [class.has-prefix]="usernameType==='mobile'">
          <mat-label>{{(usernameType==='mobile')? 'Mobile' : (usernameType==='email')? 'Email' :
            'Email or Mobile' }}</mat-label>
          <input [class.pl-3]="usernameType==='mobile'" matInput placeholder="Enter email or mobile number" type="text"
            formControlName="userName" (ngModelChange)="changeInputType()" name="username" value="" autocomplete="new-username">
          <span class="prefix" matTextPrefix *ngIf="(usernameType==='mobile')">{{selectedCountry?.phoneCode}}</span>

          <mat-icon matSuffix>{{(usernameType==='email')? 'email' : (usernameType==='mobile') ? 'call' : ''}}</mat-icon>
        </mat-form-field>


        <mat-form-field appearance="outline" *ngIf="!loginWithOpt">
          <mat-label>Enter your password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" name="password" value=""
          autocomplete="new-password"/>
          <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon class="material-icons-outlined">{{
              hide ? "visibility_off" : "visibility"
              }}</mat-icon>
          </button>
        </mat-form-field>

        <app-otp-field *ngIf="loginWithOpt" (otpSubmit)="getOtp($event)"></app-otp-field>
        <div class="vertical-height"></div>

        <button mat-flat-button class="full-width" color="primary" [disabled]="fetching || !loginForm.valid">
          <span [class.vHidden]="fetching">Log in</span>
          <mat-spinner color="primary" *ngIf="fetching" diameter="20"></mat-spinner>
        </button> 
        <div class="secondary-action foreground-5 mb-2">
          Forgot Password?  <a class="accent"  (click)="mode = 'forgot'">Retrive here</a>
        </div>

        <button mat-stroked-button type="button" class="full-width" color="primary" (click)="switchLoginMode()">
          {{loginWithOpt ? 'Login with Password' : 'Login with Otp' }}
        </button>


        <!-- sdfds -->

        <!-- <div class="form-container">
          <p>Check your Mobile or Email to get 4 digit OTP pin for register.</p>
          <form [formGroup]="VerifySignupForm" (ngSubmit)="onVerifySignup()">
            <app-otp-field (otpSubmit)="getOtp($event)"></app-otp-field>
            
          </form>
        </div> -->

        <!-- sdfds -->
      </div>
    </form>
    <!-- <label class="seprator">
            ---------------------
            OR
            ---------------------
        </label>
        <div class="btn-wrapper">
            <button mat-flat-button style="background-color: #3b579d; color: #f2f2f2;">Continue with Facebook</button>

            <button mat-flat-button style="background-color: #dc4a38; color: #f2f2f2;">Continue with Google</button>
        </div>  -->
  </div>

  <div class="inner-wrapper" *ngIf="mode == 'register'">
    <h1>
      {{ otpSentForRegister ? 'Enter OTP' : 'Register'}}
      <span>or
        <a class="cursor primary" (click)="mode = 'login'">
          login to your account
        </a>
      </span>
    </h1>
    <div class="big-vertical-height"></div>
    <ng-container *ngIf="!otpSentForRegister">
      <form [formGroup]="registerForm" (ngSubmit)="sendOtp()" autocomplete="off">
        <div class="full-width">
          <div class="row">
            <div class="col col-full">
              <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput type="text" formControlName="firstName" autocomplete="off" required />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col col-full">
              <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput type="text" formControlName="lastName" autocomplete="off" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col col-full">
              <mat-form-field appearance="outline" class="has-prefix" floatLabel="always">
                <mat-label>Mobile No</mat-label>
                <input matInput type="text" placeholder="Ex. 9039907701" formControlName="mobileNumber"
                  autocomplete="off" required />
                <span class="prefix" matTextPrefix *ngIf="selectedCountry">{{ selectedCountry?.phoneCode }}</span>
              </mat-form-field>
            </div>
          </div>
          

          <!-- Canabis fields (Starts) -->

          <div *ngIf="this.basicInfo?.businessType === 'cannabis'">
            <div class="row">
              <div class="col col-full">
                <mat-form-field appearance="outline">
                  <mat-label>Date of Birth</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="dateOfBirth" required>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="registerForm.get('dateOfBirth')?.hasError('required')">
                    Date of Birth is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
        
            <div class="row">
              <div class="col col-full">
                <mat-form-field appearance="outline">
                  <mat-label>Customer Type</mat-label>
                  <mat-select formControlName="customerType" required (ionChange)="updateValidator()">
                    <mat-option value="Medical">Medical</mat-option>
                    <mat-option value="Recreational">Recreational</mat-option>
                    <mat-option value="Processor">Processor</mat-option>
                  </mat-select>
                  <mat-error *ngIf="registerForm.get('customerType')?.hasError('required')">
                    Customer Type is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
        
            <div class="row" *ngIf="registerForm.get('customerType').value === 'Medical'">
              <div class="col col-full">
                <mat-form-field appearance="outline">
                  <mat-label>MMJ State ID Number</mat-label>
                  <input matInput type="text" formControlName="mmjStateIDNumber">
                </mat-form-field>
              </div>
            </div>

          </div>


          <!-- Canabis fields (Ends) -->

          <div class="row">
            <div class="col col-full">
              <mat-form-field appearance="outline">
                <mat-label>Enter your Email Address</mat-label>
                <input matInput type="email" formControlName="emailId" autocomplete="off" required />
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col col-full">
              <mat-form-field appearance="outline">
                <mat-label>Enter your password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required />
                <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon class="material-icons-outlined">{{ hide ? "visibility_off" : "visibility" }}
                  </mat-icon>
                </button>
                <mat-error *ngIf="registerForm?.get('password').invalid">Minimum length should be 6
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col col-full terms">
              <mat-checkbox formControlName="terms" color="primary">I have read and accept the &nbsp;
              </mat-checkbox>
              <a [routerLink]="['/terms']" target="_blank" class="opacity-5 foreground">Terms and Condition</a>
            </div>
          </div>

          <div class="row btn-wrapper">
            <div class="col col-full">
              <button mat-raised-button [disabled]="fetching || !registerForm.valid" color="primary">
                <ng-container *ngIf="!fetching">Register</ng-container>
                <mat-spinner *ngIf="fetching" diameter="20"></mat-spinner>
              </button>
            </div>
            <!-- <a mat-button class="ml-auto" (click)="goPage('terms')">Terms of Use</a> -->
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="otpSentForRegister">
      <div class="form-container">
        <p>Check your Mobile or Email to get 4 digit OTP pin for register.</p>
        <form [formGroup]="VerifySignupForm" (ngSubmit)="onVerifySignup()">
          <app-otp-field (otpSubmit)="getOtp($event)"></app-otp-field>
          <button mat-flat-button class="full-width" color="primary" [disabled]="!VerifySignupForm.valid || loading">
              <span [class.vHidden]="loading">Complete Signup</span>
              <mat-spinner color="primary" *ngIf="loading" diameter="20"></mat-spinner>
          </button> 
        
          <div class="secondary-action foreground-5">
              Didn`t receive code? <a class="accent" (click)="sendOtp()">Request Again</a>
          </div>

        </form>
      </div>
    </ng-container>

  </div>

  <div class="inner-wrapper" *ngIf="mode == 'forgot'">
    <h1>
      Forgot Password
      <span>or
        <a class="cursor primary" (click)="mode = 'login'"> Back to login</a>
      </span>
    </h1>
    <form *ngIf="!otpSent" [formGroup]="forgotForm" (ngSubmit)="onForgotSubmit()" autocomplete="off">
      <div class="flex-column full-width">
        <mat-form-field appearance="outline" [class.has-prefix]="usernameType==='mobile'">
          <mat-label>{{(usernameType==='mobile')? 'Mobile' : (usernameType==='email')? 'Email' :
            'Email or Mobile' }}</mat-label>
          <input [class.pl-3]="usernameType==='mobile'" matInput placeholder="Enter email or mobile number" type="text"
            formControlName="mobileNumber" (ngModelChange)="changeInputType2()">
          <span class="prefix" matTextPrefix *ngIf="(usernameType==='mobile')">{{selectedCountry?.phoneCode}}</span>

          <mat-icon matSuffix>{{(usernameType==='email')? 'email' : (usernameType==='mobile') ? 'call' : ''}}</mat-icon>
        </mat-form-field>

        <div class="vertical-height"></div>
        <div class="row">
          <button mat-raised-button [disabled]="fetching || !forgotForm.valid" color="primary">
            <ng-container *ngIf="!fetching">Send OTP</ng-container>
            <mat-spinner *ngIf="fetching" diameter="20"></mat-spinner>
          </button>
          <!-- <a mat-button class="ml-auto" (click)="mode='login'">Back to Login</a> -->
        </div>
      </div>
    </form>

    <form *ngIf="otpSent" [formGroup]="OtpForm" (ngSubmit)="onOtpSubmit()" autocomplete="off">
      <div class="flex-column full-width">

        <mat-form-field appearance="outline">
          <mat-label>OTP*</mat-label>
          <input matInput type="number" formControlName="otp" autocomplete="off" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>New Password*</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
          <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon class="material-icons-outlined">{{
              hide ? "visibility_off" : "visibility"
              }}</mat-icon>
          </button>
        </mat-form-field>
        <div class="row">
          <div class="success-note">
            OTP is successfully sent to your mobile number and your registerd
            email address.
          </div>
        </div>
        <div class="row">
          <button mat-raised-button [disabled]="fetching || !OtpForm.valid" color="primary">
            <ng-container *ngIf="!fetching">Reset Password</ng-container>
            <mat-spinner *ngIf="fetching" diameter="20"></mat-spinner>
          </button>

          <button mat-button class="ml-auto" (click)="onForgotSubmit()" color="primary">
            <ng-container>Resend OTP</ng-container>
          </button>

          <!-- <a mat-button class="ml-auto" (click)="mode='login'">Back to Login</a> -->
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>