import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';
@Injectable({
  providedIn: 'root'
})
export class CmsService {
  api = this.apiConfig.getBaseUrl + 'cms';

  constructor(
    private apiConfig: ApiConfigService) {
  }

  getAllCMSPages(): Observable<any> {
    return this.apiConfig.get(this.api);
  }

  getCMSPageByTitle(title): Observable<any> {
    return this.apiConfig.get(this.api + '/' + title);
  }
}
