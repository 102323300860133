import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root',
})
export class RestaurantSettingsService {
  baseApi = this.apiConfig.getBaseUrl;

  api = this.baseApi + 'configuration';

  tax = new BehaviorSubject(null);

  tipsEnable = new BehaviorSubject(null);

  processingFee = new BehaviorSubject(null);

  stripeProcessingFee = new BehaviorSubject(null);

  fixedProcessingFee = new BehaviorSubject<number>(0);

  constructor(private apiConfig: ApiConfigService) {}

  getTax() {
    this.apiConfig.get(this.api + '/getByParameter/Tax').subscribe((res) => {
      this.tax.next(res);
    });
  }

  getStripeProcessingFee() {
    this.apiConfig.get(this.baseApi + 'payment-gateway/client/stripe/fee').subscribe((res) => {
      this.stripeProcessingFee.next(res);
    });
  }

  getTipsEnableConfig() {
    this.apiConfig
      .get(this.api + '/getByParameter/enableTips')
      .subscribe((res) => {
        this.tipsEnable.next(res);
      });
  }

}
