import { AgmCoreModule } from '@agm/core';
import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AgmDirectionModule } from 'agm-direction';
import { CookieService } from 'ngx-cookie-service';
import { NgxStripeModule } from 'ngx-stripe';
import { UtilityService } from 'src/app/appServices/utility.service';
import { CustomSnackBarComponent } from 'src/app/core/custom-snack-bar/custom-snack-bar.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './appModules/core.module';
import { DesignUtilitiesModule } from './appModules/design-utilities.module';
import { GoogleAnalyticsService } from './appServices/google-analytics.service';
import { BasicAuthHtppInterceptorServiceService } from './auth/basic-auth-htpp-interceptor-service.service';
import { TenentIdentifierInterceptor } from './auth/tenant-identifier/tenent-identifier.interceptor';
import { AddressModule } from './includes/address/address.module';
import { PREDEFINED_URLS } from './appConstants/TastioUrls';
import { ServerErrorInterceptorService } from './core/appInit/server-error-interceptor.service';
import { BackupApiInterceptor } from './core/appInit/backup-api.interceptor';
import { GlobalErrorHandler } from './core/appInit/global-error-handler';
import { ErrorLoggingService } from './core/appInit/error-logging.service';
import { ErrorMessageService } from './core/appInit/error-message.service';
import * as Rollbar from 'rollbar';

//imports for firebase push-notifications
import { environment } from "../environments/environment";
import { initializeApp } from "@firebase/app";
initializeApp(environment.firebaseConfig);

export function getDomainName(utilityService: UtilityService) {
  const tastioUrls = PREDEFINED_URLS;
  if (tastioUrls.includes(utilityService.getDocumentRefrence().location.host) && utilityService.getDocumentRefrence().location.pathname !== "") {
    const domainName = "/" + utilityService.getDocumentRefrence().location.pathname?.split('/')[1];
    return domainName;
  }
}

/* Roll bar Setup Start */
const rollbarConfig = {
  accessToken: 'e0fb77115d2744b4ab5955db2ef48f0d',
  captureUncaught: true,
  captureUnhandledRejections: true,
};


export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

/* Roll bar Setup End */

@NgModule({
  declarations: [
    AppComponent,
    CustomSnackBarComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'customerAppV2' }),
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD5hBJSECW_-0DD2NchivIu_muVyR3wx8w',
      libraries: ['places', 'geometry']
    }),
    AgmDirectionModule,
    AppRoutingModule,
    CoreModule,
    DesignUtilitiesModule,
    TransferHttpCacheModule,
    NgxStripeModule.forRoot(),
    AddressModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useFactory: getDomainName, deps: [UtilityService] },
    CookieService, GoogleAnalyticsService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthHtppInterceptorServiceService, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenentIdentifierInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptorService, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackupApiInterceptor,
      multi: true,
    },
    ErrorLoggingService, ErrorMessageService
  ],
  bootstrap: [AppComponent],
  entryComponents: [CustomSnackBarComponent]
})
export class AppModule { }
