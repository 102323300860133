import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IStore } from 'src/app/appModels/IStore';
import { CartService } from 'src/app/appServices/cart.service';
import { GoogleAnalyticsService } from 'src/app/appServices/google-analytics.service';
import { OrdersService } from 'src/app/appServices/orders.service';
import { StoresService } from 'src/app/appServices/stores.service';
import { RestaurantBasicInfoStorage } from 'src/app/appStorage/restaurant-basic-info.storage';
import { ToasterService } from 'src/app/core/toaster.service';
import { CheckoutHelperService } from 'src/app/pages/checkout/checkout-helper.service';
import { ThanksHelperService } from 'src/app/pages/thanks/thanks-helper.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-stripe-card-payment',
  templateUrl: './stripe-card-payment.component.html',
  styleUrls: ['./stripe-card-payment.component.scss']
})
export class StripeCardPaymentComponent extends HandleSubscriptionsComponent implements OnInit {

  basicInfo: any;
  disablePayButton = false;
  fetching: boolean = false;
  selectedStore: IStore;
  publicKeyForFirstTranscation: string;
  stripeTest: FormGroup;

  elementsOptions: StripeElementsOptions = {
    //locale: 'es'
  };


  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#7A7A7A',
        lineHeight: '40px',
        fontWeight: '600',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };


  constructor(
    public dialogRef: MatDialogRef<StripeCardPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private orderService: OrdersService,
    private toasterMessage: ToasterService,
    private router: Router,
    private cartService: CartService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private infoStorage: RestaurantBasicInfoStorage,
    private thanksHelperService: ThanksHelperService,
    private checkoutHelperService: CheckoutHelperService,
    private storesService: StoresService
  ) {
    super();
  }

  ngOnInit(): void {
    // this.stripeService.setKey(this.data.paymentGatewayModel.attributeValueModels[0]?.attributeValue);
    // this.stripeService.setKey(environment.STRIPE_PUBLIC_KEY);
    
    this.getBasicInfo();
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required, this.noLeadingWhitespaceValidator]]
    });
    this.subscribeSelectedStore();
  }

 
  // Custom validator to check for leading whitespace
  noLeadingWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
    const hasLeadingWhitespace = control.value?.startsWith(' ');
    return hasLeadingWhitespace ? { leadingWhitespace: true } : null;
  }

  
  /**
   * subscribe selected store
   */
  subscribeSelectedStore() {
    this.handleSubscription(this.checkoutHelperService.selectedStore,
      (res) => {
        if (res) {
          this.selectedStore = res;
          this.getStripeAcByStoreId();
        }
      }
    );
  }


  /**
   * Get Stripe Ac by Store id
   */
  getStripeAcByStoreId(){
    this.handleSubscription(this.storesService.getStripeAcByStoreId(this.selectedStore.id), 
    (res) =>{
      console.log( 'res ',res)
      this.publicKeyForFirstTranscation = res?.publicKey;
      console.log( 'publicKeyForFirstTranscation ',this.publicKeyForFirstTranscation);
      this.stripeService.setKey(this.publicKeyForFirstTranscation);
    })
  }


  buy(): void {
    this.fetching = true;
    this.disablePayButton = true;
    const name = this.stripeTest.get('name').value;
    this.handleSubscription(this.stripeService
      .createToken(this.card.element, { name }),
      (result) => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          // console.log(result.token.id);
          this.chargeCard(result.token.id);
        } else if (result.error) {
          this.disablePayButton = false;
          // Error creating the token
          console.warn(result.error.message);
          this.googleAnalyticsService.eventEmitter('Purchase_flow', 'purchase_failed', 'Bag failed to be purchased', 'click', 5);
          this.toasterMessage.errorMessage(result.error.message);
        }
      }, err => {
        this.fetching = false;
        this.disablePayButton = false;
        this.googleAnalyticsService.eventEmitter('Purchase_flow', 'failed_to_create_order', 'Failed to create order', 'click', 5);
        this.toasterMessage.errorMessage('Failed to Create Order');
      }
    );
  }

  chargeCard(token: string): void {
    this.fetching = true;
    this.data.stripePaymentModel = {
      paymentToken: token
    }
    if(this.data?.id){
      this.paySavedOrderByOrderId(token);
    }else{
      this.payOrder(this.data, 'stripe');
    }
  }

  
  /**
   * pay saved order
   * @param token 
   */
  paySavedOrderByOrderId(token: string) {
    const temp = {
      paymentToken: token,
      totalAmount: this.data.totalAmount,
      processingFee: this.data.processingFee
    }
    this.handleSubscription(this.orderService.markOrderAsPaidByManualCard(this.data.id, temp),
      (res)=>{
        this.fetching = false;
        this.postPayment();
      },
      (err)=>{
        this.fetching = false;
        this.disablePayButton = false;
        this.toasterMessage.errorMessage('Error in creating order, If any money detected from your account will be refund.');
      },
    );
  }


/**
  * pay order with out order id 
  * @param order 
  * @param paymentGatewayName 
  */
  payOrder(order: any, paymentGatewayName: any){
    this.handleSubscription(this.orderService.createOrder(order, paymentGatewayName),
      (res) => {
        this.fetching = false;
        this.data.id = res;
        this.googleAnalyticsService.eventEmitter('Purchase_flow', 'charge_card', 'Card has been charged', 'click', 5);
        this.postPayment();
      }, err => {
        this.fetching = false;
        this.disablePayButton = false;
        this.toasterMessage.errorMessage('Error in creating order, If any money detected from your account will be refund.');
        this.googleAnalyticsService.eventEmitter('Purchase_flow', 'charge_card', 'Card has failed to be charge', 'click', 5);
      }
    );
  }



  postPayment(): void {
    this.disablePayButton = false;
    this.toasterMessage.successMessage('Order is successfully placed');
    this.dialogRef.close('PAYMENT_DONE');
    this.cartService.cart.next(null);
    this.thanksHelperService.currentOrder.next(this.data);
    // this.router.navigate(['/thanks']);
  }

  /**
   * get basic information
   */
  getBasicInfo(): void {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  onCloseModel(): void {
    this.dialogRef.close('CLOSE');
  }

}