import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaLinkService {

  api = this.apiConfig.getBaseUrl + 'social-media-link';

  constructor(
    private apiConfig: ApiConfigService) { }

  getSocialMediaLinks(): Observable<any> {
    return this.apiConfig.get(this.api);
  }

}
