import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class RestaurantInfoService {
  api = this.apiConfig.getBaseUrl + 'account-settings';

  constructor(
    private apiConfig: ApiConfigService) { }

  getBasicInfo(): Observable<any> {
    return this.apiConfig.get(this.api);
  }

}
