<div class="address-container">
    <button class="close" mat-icon-button (click)="onCloseModel()">
        <mat-icon>close</mat-icon>
    </button>
    <ng-container *ngIf="data && data.noOfAddresses === 0">
        <ng-container *ngIf="!isDeliveryEnable">
            <h3 class="align-center title">Delivery coming soon!</h3>
            <p>We are currently in the process of providing delivery to our amazing customers, and would like to see how
                many customers would be interested in Delivery.</p>
        </ng-container>
        <ng-container *ngIf="isDeliveryEnable">
            <h3 class="align-center title">Delivery has just been released!</h3>
            <p>Our most requested feature ever has finally been released! Add your address now to save some time on
                checkout.</p>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="data && data.noOfAddresses === undefined">
        <h3 class="align-center">Update Address</h3>
    </ng-container>
    <ng-container *ngIf="!data">
        <h3 class="align-center">Add New Address</h3>
    </ng-container>


    <div class="row">
        <div class="col-left">
            <div class="bg-app">
                <form>
                    <mat-form-field class="example-full-width" cdkFocusInitial>
                        <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                            placeholder="Search Nearest Location" matInput autocorrect="true" autocapitalize="true"
                            spellcheck="true" type="text" #search>
                    </mat-form-field>
                </form>

                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" >
                    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                        (dragEnd)="markerDragEnd($event)"></agm-marker>
                </agm-map>
            </div>
        </div>
        <div class="col-right">
            <div class="address-info bg-app">
                <form [formGroup]="addressForm">
                    <div class="item">
                        <mat-form-field appearance="outline" style="width: 100%" >
                            <mat-label>Address</mat-label>
                            <textarea matInput rows="3" formControlName="address" placeholder="Enter full address"
                                required readonly></textarea>
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label>Flat/Apartment Number (Optional)</mat-label>
                            <input matInput formControlName="landmark" placeholder="Enter Flat/Apartment Number" />
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field appearance="outline" style="width: 100%" >
                            <mat-label>Pincode (Optional)</mat-label>
                            <input matInput formControlName="pincode" placeholder="Enter pincode" />
                        </mat-form-field>
                    </div>
                
                    <div class="item">
                        <mat-form-field appearance="outline" style="width: 100%" >
                            <mat-label>Note (Optional)</mat-label>
                            <input matInput formControlName="note" placeholder="Enter note for delivery" />
                        </mat-form-field>
                    </div>
                    <div class="item">
                        <mat-form-field appearance="outline">
                            <mat-label>Address Type</mat-label>
                            <mat-select formControlName="addressType" required>
                                <mat-option *ngFor="let type of typeList" [value]="type">
                                    {{type.addressType}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- [disabled]="addressForm.status !== 'VALID' || notInVisibility"  -->
                    <div class="btn-row">
                        <button mat-flat-button [disabled]="fetching || !addressForm.valid"
                            color="primary" type="submit" (click)="saveAddress()">
                            <ng-container *ngIf="!fetching">{{data ? 'Update' : 'Save'}}</ng-container>
                            <mat-spinner *ngIf="fetching" diameter="20"></mat-spinner>
                        </button>

                        <button class="ml-3" mat-dialog-close mat-stroked-button color="primary" aria-label="Cancel">
                            Cancel
                        </button>
                    </div>


                </form>
            </div>
        </div>
    </div>


</div>