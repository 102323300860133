import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressComponent } from './address.component';
import { MatModule } from '../../appModules/mat-module';
import { SelectAddressModule } from '../../appModules/select-address/select-address.module';


@NgModule({
  declarations: [AddressComponent],
  imports: [
    CommonModule,
    MatModule,
    SelectAddressModule
  ],
  exports: [AddressComponent]
})
export class AddressModule { }
