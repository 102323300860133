<form (ngSubmit)="onSubmit()" [formGroup]="Form">
  <div class="otp-field">
    <ng-container *ngFor="let input of formInput;  index as i">
      <input #formRow type="text" formControlName="{{input}}" class="cus-input dark" maxlength="1" 
      (keyup)="keyUpEvent($event, i)"
      [class.error]="Form.controls[input].hasError('pattern')"
      (paste)="paste($event)">
    </ng-container>
  </div>
  <!-- <button type="submit" class="btn btn-primary">Submit</button> -->
</form>