import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-orders',
  templateUrl: './no-orders.component.html',
  styleUrls: ['./no-orders.component.scss']
})
export class NoOrdersComponent implements OnInit {
  
  @Input() type;
  title:string;
  description:string;
  constructor() { }

  ngOnInit(): void {
    if(this.type=='bag'){
      this.title =' No Item in Bag';
      this.description = 'Looks like you haven`t add item in bag.'
    }else if(this.type =='order'){
      this.title =' No Orders Yet';
      this.description = 'Looks like you haven`t made any orders yet.'

    }
  }

}