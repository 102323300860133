import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class InternationalService {

  api:string = environment.tenantApi;
   

  constructor(private httpClient: HttpClient) { }

  
  getCountries(): Observable<any> {
    return this.httpClient.get(this.api + "/country");
  } 
  

  
  getCountryName(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: any) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            const geocoder = new google.maps.Geocoder();
            const latlng = new google.maps.LatLng(latitude, longitude);

            geocoder.geocode({ 'location': latlng }, (results: any, status: any) => {
              if (status === google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                  const country = results[0].address_components.find((component: any) =>
                    component.types.includes('country')
                  );

                  const countryName = country.long_name;
                  resolve(countryName);
                } else {
                  reject('No results found.');
                }
              } else {
                reject('Geocoder failed due to: ' + status);
              }
            });
          },
          (error: any) => {
            reject('Error retrieving geolocation: ' + error.message);
          }
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  }
  
}
