import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  api = this.apiConfig.getBaseUrl + 'tenant';

  constructor(
    private apiConfig: ApiConfigService) {
  }


  /**
   * get tenant name by url
   * @param url 
   */
  getTenantByUrl(url: string): Observable<any> {
    return this.apiConfig.get(this.api + '?url=' + url);
  }

  /**
   * Get tenant by domain
   * @param domain 
   * @returns 
   */
  getTenantByDomain(domain: string): Observable<any> {
    return this.apiConfig.get(this.api + '/domain/' + domain);
  }
}
