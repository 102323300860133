import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUser } from '../appModels/IUser';
import { ApiConfigService } from './apiConfigService';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  api = this.apiConfig.getBaseUrl;
  constructor(private apiConfig: ApiConfigService) { }
  // api = this.apiConfig.getBaseUrl;
  user: IUser = null;
  loggedIn = new BehaviorSubject(this.user);

  login(user): Observable<any> {
    // user.userRoleModel = {
    //   userRole: 'customer',
    // };
    return this.apiConfig.post(this.api + 'authenticate', user);
  }
  signup(user): Observable<any> {
    return this.apiConfig.post(this.api + 'user/signUp', user);
  }
  sendOtp(payload): Observable<any> {
    return this.apiConfig.post(this.api + 'user/generate/OTP', payload);
  }

  
  logout(): Observable<any> {
    return this.apiConfig.get(this.api + 'logout');
  }
  // forgot(number): Observable<any> {
  //   return this.httpClient.get(this.api + '/user/generateOTP/'+ number, { withCredentials: true });
  // }

  updateProfile(user: IUser): Observable<any> {
    return this.apiConfig.put(this.api + 'user', user);
  }
  updatePassword(passwordForm): Observable<any> {
    return this.apiConfig.put(this.api + 'user/password', passwordForm);
  }
  updatePasswordWithOutOTP(passwordForm): Observable<any> {
    return this.apiConfig.put(this.api + 'user/update/password', passwordForm);
  }

  getProfile(): Observable<any> {
    return this.apiConfig.get(this.api + 'user/profile');
  }

  generateOTP(mobileNumber): Observable<any> {
    return this.apiConfig.get(this.api + 'user/generateOTP/' + mobileNumber);
  }

  generateEmailOTP(emailId): Observable<any> {
    return this.apiConfig.get(this.api + 'user/generateOTP/email/' + emailId);
  }

  updateUserDevice(payload): Observable<any> {
    return this.apiConfig.put(this.api + 'user/device', payload);
  }

  verifyOTP(payload): Observable<any> {
    return this.apiConfig.post(this.api + 'user/verifyOTP', payload);
  }
}
