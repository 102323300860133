import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../apiConfigService';


@Injectable({
  providedIn: 'root'
})
export class CannabisSettingsService {

  api:string  = this.apiConfig.getBaseUrl + 'cannabis-settings';


  constructor(private apiConfig: ApiConfigService) { }

  updateCannabisSettings(settings: any): Observable<any> {
    return this.apiConfig.put(this.api, settings, {withCredentials: true});
  }
  getSettings(): Observable<any> {
    return this.apiConfig.get(this.api+'/getAll');
  }

  syncInventory(): Observable<any> {
    return this.apiConfig.get(this.api+'/inventory/checkup');
  }

  getSettingsByStore(storeId: number): Observable<any> {
    return this.apiConfig.get(this.api+`/getAll/${storeId}`);
  }

}
