import { Component, HostBinding, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { AppInitializerService } from 'src/app/core/appInit/app-initializer.service';
import { environment } from 'src/environments/environment';
import { HandleSubscriptionsComponent } from './appDirectives/handle-subscriptions.directive';
import { RestaurantBasicInfoStorage } from './appStorage/restaurant-basic-info.storage';
import { AgeProofPromptComponent } from './includes/popups/age-proof-prompt/age-proof-prompt.component';
import { PromptComponent } from './includes/popups/prompt/prompt.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends HandleSubscriptionsComponent implements OnInit {
  //------------------------------------------// 
  // Properties Area                          //
  //------------------------------------------// 
  S3_URL = environment.S3_URL;
  storage: any;
  tenantName: string;
  sidenav = false;



  get isShow() {
    return this._appInit.checkScroll();
  }

  get restaurantInfo() {
    return this._appInit.restaurantInfo;
  }
  get showNotificationBar() {
    return this._appInit.showNotificationBar;
  }

  @HostBinding('class') componentCssClass;

  constructor(
    private _appInit: AppInitializerService,
    private _du: DesignUtilityServices,
    public dialog: MatDialog,
    private infoStorage: RestaurantBasicInfoStorage
  ) {
    super();
    this.getThemeClass();
  }

  ngOnInit(): void {
    this._appInit.appInitializer();
    this.getBasicInfo();


  }

  /**
* get basic info
*/
  getBasicInfo(): void {
    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res?.businessType === 'cannabis') {
          this.openAgeProofModel();

        }
      }
    );
  }



  gotoTop() { this._appInit.gotoTop() };

  getThemeClass() {
    this.handleSubscription(this._du.theme,
      (res) => {
        this.componentCssClass = res
      }
    );
  }



  openAgeProofModel(): void {
    this.dialog.open(AgeProofPromptComponent, { disableClose: true });
  }
}
