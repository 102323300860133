<mat-dialog-content class="mat-typography custom-scrollbar change-password-wrapper" >
    <a mat-icon-button (click)="onCloseModel()" class="close">
        <mat-icon class="material-icons-outlined">close</mat-icon>
    </a>
    <div class=" full-width">
        <h1>Change Password</h1>
        <form [formGroup]="Form" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="flex-column full-width">
                <mat-form-field appearance="outline">
                    <mat-label>Current Password</mat-label>
                    <input matInput 
                    [type]="hide ? 'password' : 'text'"
                    formControlName="currentPassword" >
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon class="material-icons-outlined">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>New Password</mat-label>
                    <input matInput 
                    [type]="hide2 ? 'password' : 'text'"
                    formControlName="updatedPassword" >
                    <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide2">
                        <mat-icon class="material-icons-outlined">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <div class="row">

                    <button type="submit" color="primary" 
                    mat-raised-button class="full-width" 
                        [disabled]="fetching || !Form.valid">
                            <ng-container *ngIf="!fetching">Change Password</ng-container>
                            <mat-spinner *ngIf="fetching" diameter="20"></mat-spinner>
                    </button>
                </div>
                
            </div>
        </form>
         
    </div> 

</mat-dialog-content>