<section class="store" *ngIf="stores">
    <div class="container">
        <h1 class="align-center">
            <mat-icon color="primary">storefront</mat-icon>
             Choose your Store
        </h1>
        <mat-dialog-content>
            <div class="store-list">
                <mat-card class="store-box bg-app mat-elevation-z0" *ngFor="let store of stores">
                    <figure>
                        <img [src]="S3_URL+''+basicInfo.bucketName+'/'+store?.imageLink" alt="Store Image">
                    </figure>
                    <h3>{{store.storeName}}</h3>
                    <a class="address foreground" [attr.href]="'http://maps.google.com/?q='+store.address" target="_blank">
                        <mat-icon color="primary">share_location</mat-icon>
                        <span class="txt">{{store.address}}</span>
                    </a>
                    <button class="cta" mat-stroked-button color="primary" *ngIf="store.active" (click)="onSelectStore(store)">
                        <mat-icon>ads_click</mat-icon>
                        Select this Store
                    </button> 
                    <button class="cta" mat-stroked-button disabled *ngIf="!store.active">
                        <mat-icon>ads_click</mat-icon>
                        Opening Soon
                    </button> 
                </mat-card>
            </div>
        </mat-dialog-content>
    </div>
</section>