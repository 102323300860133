import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './apiConfigService';
@Injectable({
  providedIn: 'root'
})
export class DeliveryTypeService {
  api = this.apiConfig.getBaseUrl;

  constructor(
    private apiConfig: ApiConfigService) { }

  getDeliveryMethods(storeId: number): Observable<any> {
    return this.apiConfig.get(`${this.api}store/${storeId}/orderTypes/customer`);
  }

}
