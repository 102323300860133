import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { IAddress } from 'src/app/appModels/IAddress';
import { IStore } from 'src/app/appModels/IStore';
import { AddressService } from 'src/app/appServices/address.service';
import { CartService } from 'src/app/appServices/cart.service';
import { OrdersService } from 'src/app/appServices/orders.service';
import { DeliveryTypeStorage } from 'src/app/appStorage/delivery-type.storage';
import { ToasterService } from 'src/app/core/toaster.service';
import { StripeCardPaymentComponent } from 'src/app/includes/payment-gateway/stripe-card-payment/stripe-card-payment.component';

@Injectable({
  providedIn: 'root'
})
export class CheckoutHelperService {

  deliveryType = new BehaviorSubject(null);
  timeSlot = new BehaviorSubject(null);
  vehicleInfo = new BehaviorSubject<any>(null);
  subTotalAmount = new BehaviorSubject(0);
  totalAmount = new BehaviorSubject(0);
  tipAmount = new BehaviorSubject(0);
  processingFee = new BehaviorSubject(0);
  couponCode = new BehaviorSubject(null);
  discount = new BehaviorSubject(0);
  selectedPaymentMethod = new BehaviorSubject(null);
  loyaltyPointsModal = new BehaviorSubject<any>(null);
  orderMessage = new BehaviorSubject(null);
  selectedDeliveryAddress: IAddress;
  selectedStore = new BehaviorSubject<IStore>(null);
  orderCheckoutDataList = new BehaviorSubject(undefined);
  checkoutUnfilledFormDetected = new Subject();
  platformFee = new BehaviorSubject(0);
  isTaxIncluded = new BehaviorSubject<boolean>(false);

  constructor(
    private toastrMessage: ToasterService,
    private orderService: OrdersService,
    private cartService: CartService,
    private router: Router,
    private addressService: AddressService,
    public dialog: MatDialog,
    private deliveryTypeStorage: DeliveryTypeStorage
  ) {
    this.subscribeDeliveryAddress();
  }


  /**
   * Validate checkout details before order
   */
  validateCheckoutDetails(): boolean {
    if (!this.validateSelectedStore()) {
      return false;
    }
    if (!this.checkPaymentMethod()) {
      return false;
    }
    if (!this.validateSelectedDeliveryType()) {
      return false;
    }
    if (!this.validateTimeSlot()) {
      return false;
    }
    if (!this.validateOrderCheckoutDataList()) {
      return false;
    }
    return true;
  }

  /**
   * check payment method is selected or not
   */
  checkPaymentMethod(): boolean {
    if (this.selectedPaymentMethod.value) {
      return true;
    }
    this.toastrMessage.errorMessage('Please select payment method.');
    return false;
  }


  /**
   * validate delivery address range
   * @param deliveryAddress
   */
  validateDeliveryAddressRange(deliveryAddress): boolean {
    const destination = {
      lat: deliveryAddress.latitude,
      lng: deliveryAddress.longitude
    };
    const storeLocation = {
      lat: this.selectedStore.value.latitude,
      lng: this.selectedStore.value.longitude
    }
    const storeDistance = this.deliveryTypeStorage.getDeliveryTypeByType('Delivery').distanceZone | 0;
    if ((Number.parseFloat(this.addressService.calculateDistance(storeLocation, destination))) > storeDistance) {
      this.toastrMessage.errorMessage('We are not serving your location. Please change delivery location.');
      return false;
    }
    return true;
  }

  /**
   * Validate delivery  type
   */
  validateSelectedDeliveryType(): boolean {
    if (this.deliveryType.value.orderTypeName.toUpperCase() === 'Delivery'.toUpperCase()) {
      if (!this.selectedDeliveryAddress) {
        this.toastrMessage.errorMessage('Please add delivery address');
        return false;
      } else {
        if (!this.validateDeliveryAddressRange(this.selectedDeliveryAddress)) {
          return false;
        }
      }
    }
    return true;
  }


  /**
   * validate Time Slot
   */
  validateTimeSlot(): boolean {
    if (this.timeSlot.value) {
      return true;
    } else {
      this.toastrMessage.errorMessage('Try after some time, Time slot is not available at this moment');
      return false;
    }
  }


  /**
   * validate Order Checkout DataList
   */
  validateOrderCheckoutDataList(): boolean {
    if (this.orderCheckoutDataList.value===null) {
      this.toastrMessage.errorMessage('Please fill contact information.');
      this.checkoutUnfilledFormDetected.next();
      return false;
    }
    return true;
  }


  /**
   * validate store
   */
  validateSelectedStore(): boolean {
    if (!this.selectedStore.value) {
      this.toastrMessage.errorMessage('Please select store');
      return false;
    }
    return true;
  }

  /**
   * create delivery address
   */
  createAddress(): string {
    let address = null;
    if (this.deliveryType.value.orderTypeName.toUpperCase() === 'delivery'.toUpperCase()) {
      if (this.selectedDeliveryAddress) {
        address =
          this.selectedDeliveryAddress?.landmark + ', ' +
          this.selectedDeliveryAddress.address + ', ' +
          this.selectedDeliveryAddress?.pincode;
      }
    }
    return address;
  }

  /**
   * create Order
   */
  createOrder(orderAmount: number): void {
    const selectedPaymentMethod = this.selectedPaymentMethod.value;
    if (this.validateCheckoutDetails()) {
      const checkoutOrderPayload = {
        orderTypeModel: {
          id: this.deliveryType.value.orderTypeId
        },
        orderMessage: this.orderMessage.value,
        deliveryAddress: this.createAddress(),
        paymentType: this.selectedPaymentMethod.value.name,
        totalAmount: orderAmount,
        orderCheckoutDataList: this.orderCheckoutDataList.value,
        tips: this.tipAmount.value,
        paymentGatewayModel: selectedPaymentMethod,
        customerAddressId: this.selectedDeliveryAddress?.id,
        discount: this.discount.value,
        storeId: this.selectedStore.value?.id,
        storeAddress: this.selectedStore.value?.address,
        storeName: this.selectedStore.value?.storeName,
        processingFee: this.processingFee.value,
        couponCode: this.couponCode.value?.couponCode,
        platformFee: this.platformFee.value,
        ...(this.loyaltyPointsModal.value !== null ? {
          amountSpent: this.loyaltyPointsModal.value.amountSpent,
          loyaltyPointsSpent: this.loyaltyPointsModal.value.loyaltyPointsSpent
        } : {})
      };

      // console.log('checkoutOrderPayload ', checkoutOrderPayload)
      // return


      if (this.deliveryType.value.orderTypeName.toUpperCase() === 'delivery'.toUpperCase() && checkoutOrderPayload.deliveryAddress) {
        if (checkoutOrderPayload.orderMessage != null) {
          checkoutOrderPayload.orderMessage = this.timeSlot.value + '|' + checkoutOrderPayload.orderMessage;
        } else {
          checkoutOrderPayload.orderMessage = this.timeSlot.value;
        }
      } else {
        checkoutOrderPayload.orderMessage = this.timeSlot.value;
      }

      if (this.selectedPaymentMethod.value.title.toUpperCase() === 'card'.toUpperCase() || this.selectedPaymentMethod.value.title.toUpperCase() === 'Card Payment'.toUpperCase()) {
        this.openPaymentGateway(checkoutOrderPayload);
      } else {
        this.callOrderForCODAPI(checkoutOrderPayload);
      }
    }
  }


  disablePaymentButton = new BehaviorSubject(false);

  /**
   * create COD payment
   * @param checkoutOrderPayload
   */
  callOrderForCODAPI(checkoutOrderPayload): void {
    this.disablePaymentButton.next(true);
    this.orderService.createOrder(checkoutOrderPayload, 'cash on delivery').subscribe(res => {
      this.disablePaymentButton.next(false);
      this.toastrMessage.successMessage('Order is successfully placed');
      this.cartService.cart.next(null);
      this.router.navigate(['/thanks'], { state: { example: 'data' } }).then(() => {
      location.reload();
      });
    }, err => {
      this.disablePaymentButton.next(false);
      this.toastrMessage.errorMessage('Error in creating order');
    });
  }


  /**
   * Open payment gateway
   * @param data
   */
  openPaymentGateway(data): void {
    let dialogRef: any;
    switch (data.paymentType.toUpperCase()) {
      case 'STRIPE':
        dialogRef = this.dialog.open(StripeCardPaymentComponent, {
          data: data
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'PAYMENT_DONE') {
            this.router.navigate(['/thanks'], { state: { example: 'data' } });
            location.reload();
          }
        });
        break;
      default:
        this.toastrMessage.errorMessage('please select valid card payment');
        break;
    }
  }

  /**
   * subscribe Delivery address
   */
  subscribeDeliveryAddress() {
    this.addressService.defaultAddress.subscribe(res => {
      this.selectedDeliveryAddress = res;
    });
  }



  /**
   * Log roll bar info message for incomplete order placement
  */
  logIncompleteOrderPlacement() {
    const confirmCancelPlaceOrder = confirm("Are you sure you don't want to place order?");
    if (confirmCancelPlaceOrder === true) {
      //this.loggingService.logInfo('This user did not place order');
    }
  }

}
