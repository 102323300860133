import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DesignUtilityServices } from 'src/app/appServices/design-utility.service';
import { CmsService } from 'src/app/appServices/cms.service';
import { HelperService } from 'src/app/appServices/helper.service';
import { SocialMediaLinkService } from 'src/app/appServices/social-media-link.service';
import { AppStoreLinkService } from 'src/app/appServices/app-store-link.service';

import { RestaurantBasicInfoStorage } from 'src/app/appStorage/restaurant-basic-info.storage';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends HandleSubscriptionsComponent implements OnInit {
    basicInfo: any;
    S3_URL = environment.S3_URL;
    webView = false;
    standalone: any;
    userAgent: any;
    safari: any;
    ios: any;
    currentYear = new Date().getFullYear();
    //------------------------------------------//
    // Properties Area                          //
    //------------------------------------------//
    theme; // Global Theme
    title;
    restaurantInfo;
    social = [];

    cmsList = new Array();
    isBrowser: boolean;

    appStoreLinks = [];

    constructor(
        private _du: DesignUtilityServices,
        private cmsService: CmsService,
        private helperService: HelperService,
        private infoStorage: RestaurantBasicInfoStorage,
        private socialMediaLinkService: SocialMediaLinkService,
        private appStoreLinkService: AppStoreLinkService,
        @Inject(PLATFORM_ID) platformId: Object
    ) {
        super();
        this.isBrowser = isPlatformBrowser(platformId);
        this.handleSubscription(this.infoStorage.restaurantBasicInfo,
            (res) => {
                if (res) {
                    this.restaurantInfo = res;
                    this.title = res.restaurantName;
                }
            }
        );
        this.subscribeWebView();
        this.handleSubscription(this._du.theme,
            (res) => {
              this.theme = res
            }
        );
        this.getBasicInfo();
        if (this.isBrowser) {
            this.standalone = ('standalone' in window.navigator) && (window.navigator['standalone']);
            this.userAgent = window.navigator.userAgent.toLowerCase();
            this.safari = /safari/.test(this.userAgent);
            this.ios = /(iPhone|iPod|iPad).*AppleWebKit.*Safari/i.test(this.userAgent);
        }
    }

    ngOnInit(): void {
        this.getCMSList();
        this.getSocialMediaLinks();
        this.getAppStoreLinks();
    }

    getSocialMediaLinks() {
        this.handleSubscription(this.socialMediaLinkService.getSocialMediaLinks(),
            (res: any[]) => {
                if (res) {
                    res.forEach(socialMedia => {
                        const temp = {
                            name: socialMedia.name,
                            icon: socialMedia.name === 'facebook' ? 'fab fa-facebook-f' : socialMedia.name=== 'twitter' ? 'fab fa-twitter' : 'fab fa-instagram',
                            url: socialMedia.url
                        }
                        this.social.push(temp);
                    });
                }
            }
        );
    }

    getAppStoreLinks() {
        this.handleSubscription(this.appStoreLinkService.getAppStoreLinks(),
            (res: any[]) => {
                if (res) {
                res.forEach(store => {
                    const temp = {
                        icon : store.name === 'play store' ? 'assets/images/google-play-button.png' : 'assets/images/app-store-button.png',
                        url : store.url
                    }
                    this.appStoreLinks.push(temp);
                });
                }
            }
        );
    }


    /**
     * get all cms list
     */
    getCMSList(): void {
        this.handleSubscription(this.cmsService.getAllCMSPages(),
            (res) => {
                this.cmsList = res;
            }
        );
    }


    /**
     * subscribe webview
     */
    subscribeWebView() {
        if (this.isBrowser) {
            var standalone = ('standalone' in window.navigator) && (window.navigator['standalone']),
                userAgent = window.navigator.userAgent.toLowerCase(),
                safari = /safari/.test(userAgent),
                ios = this.isIOSorIPad();

            if (ios) {
                if (!standalone && !safari) {
                    // iOS webview
                    this.webView = true;
                } else {
                    this.webView = false;
                }
            } else {
                if (userAgent.includes('wv')) {
                    // Android webview
                    this.webView = true;
                } else {
                    this.webView = false;
                }
            }
        }

    }

    /**
     * get basic info
     */
    getBasicInfo(): void {
        this.handleSubscription(this.infoStorage.restaurantBasicInfo,
            (res) => {
                if (res) {
                    this.basicInfo = res;
                }
            }
        );
    }

    isIOS(): boolean {
        if (this.isBrowser) {
            if (/iPad|iPhone|iPod/.test(navigator.platform)) {
                return true;
            } else {
                return navigator.maxTouchPoints &&
                    navigator.maxTouchPoints > 2 &&
                    /MacIntel/.test(navigator.platform);
            }
        }

    }

    isIpadOS(): boolean {
        if (this.isBrowser) {
            return navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.platform);
        }
    }

    isIOSorIPad(): boolean {
        if (this.isBrowser) {
            if (this.isIpadOS() || this.isIOS())
                return true;
            return false;
        }
    }
}
