import { isPlatformBrowser } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PREDEFINED_URLS } from '../appConstants/TastioUrls';
import { LoginService } from '../appServices/login.service';
import { UtilityService } from '../appServices/utility.service';
import { NotificationBarService } from '../core/appInit/notification-bar.service';
import { TenentContextService } from './tenant-identifier/tenent-context.service';
import { DesignUtilityServices } from '../appServices/design-utility.service';
import { IUser } from '../appModels/IUser';


export interface RefreshTokenResponse {
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private myTokenCache: Observable<RefreshTokenResponse>;
  private myToken: string;
  private myUserId: number;
  private myTokenSubscription: Subscription;
  private stopTimer: Subject<boolean>;
  isBrowser: boolean;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private _notificationBarService: NotificationBarService,
    private cookieService: CookieService,
    private tenantContextService: TenentContextService,
    private utilityService: UtilityService,
    private _du: DesignUtilityServices,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.subscribeTenantName();
  }



  public createTokenHeader(): HttpHeaders {
    let reqOptions = new HttpHeaders().set('Content-Type', 'application/json')
    this.token = this.cookieService.get('token');
    if (this.token) {
      reqOptions = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${this.token}`);
    }
    return reqOptions;
  }

  public clear() {
    if (this.myTokenSubscription) {
      this.myTokenSubscription.unsubscribe();
    }
    if (this.stopTimer) {
      this.stopTimer.next(true);
      this.stopTimer = null;
    }
    this.myTokenCache = null;
    this.myToken = null;
    this.myUserId = null;
    this.router.navigate(['/login']);
  }

  get tokenStream(): Observable<string> {
    return this.myTokenCache.pipe(map(response => response.token));
  }

  get token(): string {
    return this.myToken;
  }

  set token(token: string) {
    this.myToken = token;
  }

  get userId(): number {
    return this.myUserId;
  }

  set userId(userId: number) {
    this.myUserId = userId;
  }


  /**
   * called this method after successfull login
   * @param res
   */
  afterLoginSuccess(userName, token): void {
    this.token = token;
    this.userId = userName;
    this.setTokenToCookies(userName, token);
    this.getUserProfile();
  }


  /**
   * set token to cookies
   * @param userName 
   * @param token 
   */
  async setTokenToCookies(userName, token) {
    if (this.isBrowser) {
      const isTastioUrl = PREDEFINED_URLS.findIndex(e => e === this.utilityService.getDocumentRefrence().location.host);
      if (isTastioUrl > -1) {
        this.cookieService.set('username', userName, 30, "/" + this.domainName);
        this.cookieService.set('token', token, 30, "/" + this.domainName);
      } else {
        this.cookieService.set('username', userName, 30);
        this.cookieService.set('token', token, 30);
      }
    }
  }


  /**
   * get User profile
   */
  getUserProfile(): void {
    if (this.cookieService.get('token')) {
      this.loginService.getProfile().subscribe(res => {
        this.loginService.loggedIn.next(res);
        this.joinLeaderboard(res);
      });
    }
  }

  joinLeaderboard(user:IUser){
    if(!user.gamertag || user.gamertag==='' || user.gamertag===null){
      this._du.joinLeaderboard();
    }
  }

  /**
   * logout
   */
  logout(): void {
    this.loginService.loggedIn.next(null);

    const isTastioUrl = PREDEFINED_URLS.findIndex(e => e === this.utilityService.getDocumentRefrence().location.host);
    if (this.isBrowser) {
      if (isTastioUrl > -1) {
        this.cookieService.delete('username', '/'+this.domainName);
        this.cookieService.delete('token', '/'+this.domainName);
      } else {
        this.cookieService.delete('username', '/');
        this.cookieService.delete('token', '/');
      }
    }
    
    this._notificationBarService.showNotificationBar.next(false);
    this.clear();
  }

  domainName: string;
  subscribeTenantName() {
    this.tenantContextService.domain.subscribe(res => {
      this.domainName = res || null;
    });
  }
}
