// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    baseUrl: 'https://main-api.preprod-tastio.com/app/v1/',
    // baseUrl: 'http://localhost:8080/app/v1/',
    backupBaseUrl: 'https://main-api.preprod-tastio.com/app/v1/',
    tenantApi: 'https://tenant-api.preprod-tastio.com/app/v1',
    backupTenantApi: 'https://tenant-api.preprod-tastio.com/app/v1',
    // baseUrl: 'http://localhost:8080/app/v1/',
    S3_URL: 'https://tastio-testing.ams3.digitaloceanspaces.com/',
    SPACE_NAME: "tastio-testing",
    firebaseConfig : {
      apiKey: "AIzaSyBvL6mZk-YWVxOSFZvBoQH7ZVqf0oixH8E",
      authDomain: "mctastio.firebaseapp.com",
      databaseURL: "https://mctastio.firebaseio.com",
      projectId: "mctastio",
      storageBucket: "mctastio.appspot.com",
      messagingSenderId: "474145913068",
      appId: "1:474145913068:web:e8f677a24163449e660dc9",
      vapidKey: "BIPDIFQjY4_34L64Wr50qIyEd0LCBggBGhHdsT1PNVuTgu5CI_LqFn9-_fSfOHBCTBoWzfzTtK-mZDZ_9Sclwn0"
    },
     STRIPE_PUBLIC_KEY: 'pk_test_51IfgJAKBSFkAswCEyeeJrPs3IrVUXwtI5JEIooa46y2egewCc7rZAjaz6ogCZQJPcbujGax3UNmtWWKh3yaU8k6D00rUBLne4r'
    //STRIPE_PUBLIC_KEY: 'pk_live_51IfgJAKBSFkAswCEmaw2GIaaMCKA6CBYDYDfoVJsyOG5PauH0Lq91HZSfqfquAmS5ynh3pMvEBaNQ9wABSGr23n100M1IhzB9T'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
