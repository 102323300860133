<div class="dialog-wrapper">
    <h4 class="mb-5">Join the Leaderboard</h4>
    <a mat-icon-button (click)="onNoClick(null)" class="close">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </a>
    <br>
    <p>Join the leaderboard programm and get Free 250 🏆 Loyalty Points</p>
    <form [formGroup]="Form" (ngSubmit)="onSubmit()">
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Gamer Tag</mat-label>
            <input matInput placeholder="Enter gamer tag" formControlName="gamertag" />
        </mat-form-field>
      <div class="mb-1">
        <button
          mat-flat-button
          color="primary"
          [disabled]="!Form.valid || loading"
        >
          <ng-container *ngIf="!loading">Submit</ng-container>
          <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        </button>
      </div>
    </form>
  </div>  