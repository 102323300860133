import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { IUser } from 'src/app/appModels/IUser';
import { LoginService } from 'src/app/appServices/login.service';
import { TokenService } from 'src/app/auth/token.service';
import { ToasterService } from 'src/app/core/toaster.service';

@Component({
  selector: 'app-join-leaderboard',
  templateUrl: './join-leaderboard.component.html',
  styleUrls: ['./join-leaderboard.component.scss']
})
export class JoinLeaderboardComponent extends HandleSubscriptionsComponent implements OnInit {

  Form: FormGroup;
  loading: boolean = false;
  user: IUser;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private toasterMessage: ToasterService,
    private tokenService: TokenService,
    public dialogRef: MatDialogRef<JoinLeaderboardComponent>,
  ) { 
    super();
  }
  
  ngOnInit(): void {
    this.Form = this.fb.group({
      gamertag: ['', [Validators.required]]
    });
    this.subscribeLoggedInUser();
  }

  

  /**
   * Subscribe User
   */
  subscribeLoggedInUser(): void {
    this.handleSubscription(this.loginService.loggedIn,
      (res) =>{
        if(res){
          this.user = res;
          this.Form.get('gamertag').patchValue(this.user.gamertag);
        }
      }
    );
  }


  onNoClick(id: number|null): void {
    this.dialogRef.close(id);
  }

  /**
   * add menu
   */
  onSubmit() {
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;
    const payload = {
      ...this.user,
      gamertag:this.Form.value.gamertag
    }

    this.handleSubscription(this.loginService.updateProfile(payload),
      (res) => {
        this.toasterMessage.successMessage('Gamer tag added successfully.');
        this.getUserProfile();
        this.loading = false;     
        this.dialogRef.close();
      }, err => {
        this.toasterMessage.errorMessage('Profile update failed.');
        this.loading = false;
      }
    );
  }

  getUserProfile(){
    this.tokenService.getUserProfile();
  }

}
